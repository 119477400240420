.transaction-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  margin: 0 auto;

  .btn-blue {
    margin-top: 2rem;

    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
}