.request {
  .content-wrapper {
    padding-top: 50px;
  }

  .text-holder {
    padding: 20px 0;
    margin: 0 auto;
    max-width: 800px;
  }

  .btn-holder {
    display: flex;

    .btn-blue {
      margin: 0 auto;
      width: 200px;
    }
  }
}

@include respond-to(sm-down) {
  .request {
    .content-wrapper {
      padding-top: 0;
    }

    .text-holder {
      padding: 10px 0 20px;
    }
  }
}

@include respond-to(xs) {
  .request {
    .btn-holder {
      .btn-blue {
        width: 100%;
      }
    }
  }
}