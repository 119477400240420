.tab-member-account {
  max-width: 650px;
  margin: 0 auto;

  .infinity-card-img {
    margin: 0 auto;
  }

  &.member-transaction {
    max-width: 100%;
  }

  .heading {
    text-align: center;
    padding-bottom: 25px;
  }

  .member-card {
    position: relative;

    width: 500px;
    height: 285px;
    padding: 20px;
    margin: 0 auto;
    
    background-size: contain;

    &.basic {
      background-image: url('../assets/images/card-basic.jpg');
    }

    &.silver {
      background-image: url('../assets/images/card-silver.jpg');
    }

    &.gold {
      background-image: url('../assets/images/card-gold.jpg');
    }

    &.platinum {
      background-image: url('../assets/images/card-platinum.jpg');
    }

    .member-card-info {
      position: absolute;
      bottom: 20px;
    }
    
    .member-name {
      margin: 0;
  
      font-size: 16px;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
    }
  
    .member-card-number {
      margin: 0;
  
      font-size: 35px;
      font-weight: 600;
      color: $white;
      letter-spacing: 5px;
    }
  
    .member-bottom-info {
      display: flex;
      justify-content: space-between;
    }
  
    .member-expiration {
      color: $white;
      font-size: 13px;
    }
  
    .member-reference {
      color: $white;
      font-size: 13px;
    }
    
    .tier-logo {
      position: absolute;
      right: 7.5%;
      bottom: 0;

      width: 75px;
      height: 75px;

      background-image: url('../assets/images/card-tier.jpg');
      background-size: cover;

      .member-tier {
        position: absolute;
        top: 55%;

        width: 100%;
        
        font-size: 30px;
        color: $white;
        text-align: center;

        transform: translateY(-50%);
      }
    }
    
  }

  .info-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;

    .points-card,
    .referrals-card,
    .expiration-card {
      padding: 15px;
      width: 31%;

      background-color: $white;
      border-radius: 7px;

      p {
        color: $gray;
        font-size: 12px;
      }

      h4 {
        margin-top: 10px;
        margin-bottom: 0;

        font-size: 18px;
      }
    }

    .membership-card,
    .tier-card,
    .category-card {
      padding: 15px;
      margin-top: 1rem;
      width: 100%;

      background-color: $white;
      border-radius: 7px;

      h4 {
        margin: 0;

        font-size: 18px;
      }
    }
  }

  .text-blue {
    display: flex;
    flex: 100%;
    justify-content: center;
    padding: 15px 0;

    cursor: pointer;
  }
}