.about {
  .card-wrapper {
    max-width: 700px;
  }

  .content {
    padding-bottom: 10px;
  }

  .text-bold {
    font-family: $font-gotham-bold;
  }

  .category-wrapper {
    background-color: white;
    padding: 15px 0;
    // border: 1px solid;
    // margin-bottom: 15px;
  }

  a {
    font-family: $font-gotham-bold;
    color: $teal;
  }

  // .category {
  //   &:first-child {
  //     .category-wrapper {
  //       .text-bold {
  //         color: $dodger-blue;
  //       }
  //     }
  //   }
  // }

  ul {
    li {
      &:before {
        content: '\2022';

        margin-right: 10px;
        
        font-size: 17px;
      }
    }
  }

  li {
    padding-bottom: 7px;
  }
}