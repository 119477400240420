.section-breadcrumbs {
  padding-bottom: 25px;

  &.m-3 {
    margin: 15px 0;
  }

  .container {
    max-width: 100%;
  }

  .breadcrumbs-wrapper {
    display: flex;
    align-items: center;
  }

  .breadcrumbs-link {
    position: relative;

    padding-left: 25px;

    font-family: $font-gotham;
    font-weight: 700;
    color: $black;
    text-decoration: none;

    &.text-large {
      padding-left: 35px;

      font-size: 25px;
      font-weight: 500;
    }

    @include on-event() {
      color: $teal;
    }
  }

  .btn-holder {
    margin-left: auto;
  }

  // .back-btn {
  //   position: absolute;
  //   top: calc(50% + 2px);
  //   left: 0;

  //   transform: translateY(-50%);
    
  //   width: 25px;
  //   height: 25px;
    
  //   cursor: pointer;

  //   &:before {
  //     content: '';
      
  //     position: absolute;
  //     top: 7px;
  //     right: initial;
  //     bottom: initial;
  //     left: 5px;
      
  //     width: 13px;
  //     height: 2px;
      
  //     background-color: $black;

  //     transform: rotate(135deg);
  //   }

  //   &:after {
  //     @extend .back-btn:before;

  //     top: 15px;
      
  //     transform: rotate(45deg);
  //   }
  // }
}