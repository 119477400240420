//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Gotham Font Family
$font-gotham: 'Gotham';
$font-gotham-bold: 'Gotham-Bold';

/// Regular font family
/// @type List
$text-font-stack: 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Copy text color
/// @type Color
$text-color: #222 !default;

/// Main brand color
/// @type Color
$brand-color: #E50050 !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../assets/' !default;

// Other Variables

$white: #ffffff;
$black: #000000;

$red: #ff0000e0;
$yellow: #ffd200;
$blue: #286df9;
$light-blue: #e9f0ff;
$lightest-blue: #f2f4f9;
$dodger-blue: #286df9;
$teal: #106397;
$pale-blue: #c7d3f9;
$lavender-blue: #ccdcfd;
$gray: #c6cad3;
$gray-100: #7e7e7e;
$gray-135: #878787;
$gray-144: #909090;
$ghost-white: #f5f8ff;
$snow: #f9f9f9;
$white-smoke: #f2f1f6;
$dirty-white: #f0f3f8;
$lavender: #e8e7eb;
$sandybrown: #fbbf4b;