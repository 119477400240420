.radio.form-input {
  input[type="radio"] {
    @include appearance(radio);
  }
}

.radio-wrapper {
  &.have-display {
    display: flex;
    max-width: 40%;
  }

  justify-content: space-between;

  &.terms-privacy {
    display: block;
    max-width: unset;

    .link {
      color: $teal;

      @include on-event {
        color: $black;
      }
    }
  }

  .form-field {
    display: flex;
    align-items: center;
    align-content: center;

    label {
      // position: relative;
      top: 0;
      left: 0;
      width: 22px;
    }

    .radio-text {
      padding-left: 30px;
      font-family: "Gotham-Bold";
      color: $gray-144;
    }
  }

  .radio {
    position: relative;
  }

  .radio input {
    position: relative;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border: 2px solid #eee;
    border-radius: 5px;
    cursor: pointer;
  }

  .radio:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .radio input:checked ~ .checkmark {
    background-color: $teal;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio input:checked ~ .checkmark:after {
    display: block;
  }

  .radio .checkmark:after {
    top: 4px;
    left: 3.5px;
    width: 11px;
    height: 7px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
}

@media screen and (max-width: 575px) {
  .radio-wrapper {
    max-width: 70%;
  }
}
