.form-wrapper {
  .mixin-focus-label {
    top: 10px;

    font-size: 13px;
    color: $gray-135;

    pointer-events: none;
  }

  .mixin-input-label {
    top: 27px;

    font-size: 15px;
    color: $gray-135;
    cursor: auto;
  }

  .phoneInput {
    padding-right: 100;
  }

  .form-col {
    display: flex;
    justify-content: space-between;

    .form-field {
      width: 48%;
    }
  }

  .form-field {
    position: relative;

    margin-bottom: 20px;

    .text-required {
      color: $red;
    }

    label {
      position: absolute;
      top: 27px;
      left: 15px;
      z-index: 1;

      display: -webkit-box;
      margin-bottom: 0;
      // width: calc(100% - 15px);
      overflow: hidden;

      font-family: $font-gotham-bold;
      font-size: 15px;
      color: $gray-135;
      text-transform: uppercase;
      text-overflow: ellipsis;

      // transition: all .15s ease-in-out;
      transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);

      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

      @extend .mixin-input-label;

      &.label-fixed {
        @extend .mixin-focus-label;
      }
    }

    &.phone {
      input[type="number"] {
        padding: 10px 70px;
      }
    }

    &.calendar,
    &.select,
    &.phone {
      label {
        top: 10px;

        width: calc(100% - 15px);

        font-size: 13px;
        color: $gray-135;
      }
    }

    @include on-event {
      .form-select-caret-down,
      .form-input-calendar {
        path {
          fill: $blue;
        }
      }
    }
  }

  .form-input {
    z-index: 2;

    padding: 15px 15px 0;
    width: 100%;
    height: 70px;

    outline: 0;

    font-family: $font-gotham;
    font-size: 15px;
    // text-transform: capitalize;

    background: $white-smoke;
    border: 1px solid $ghost-white;
    border-radius: 5px;

    // transition: all .2s ease;

    // Test
    &:valid,
    &:focus,
    &:disabled {
      ~ label {
        @extend .mixin-focus-label;
      }
    }

    &.pointer-disabled {
      pointer-events: none;
    }

    &.not-required {
      ~ label {
        @extend .mixin-input-label;
      }

      &:focus {
        ~ label {
          @extend .mixin-focus-label;
        }
      }
    }

    // &:invalid {
    //   background-color: black;
    // }

    &::placeholder {
      color: $black;
      opacity: 0;
    }

    // Test
    &.select {
      cursor: pointer;
    }
    &.checkbox_container {
      width: 50%;
    }
  }

  .form-error {
    font-family: $font-gotham;
    font-weight: 500;
    font-size: 14px;
    color: red;
  }

  .form-textarea {
    padding: 19px 0 15px;

    textarea {
      padding: 15px;
      height: 150px;

      resize: none;
    }

    label {
      top: 0;
      left: 0;

      padding: 15px 15px 0;
      width: 100%;

      background-color: $white-smoke;
    }

    // Test
    .form-input:valid,
    .form-input:focus {
      ~ label {
        top: 0;
      }
    }
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.form-group-checkbox {
      justify-content: flex-start;
      padding: 15px 0;

      .checkbox-wrapper {
        width: 50%;
      }
    }

    .form-field {
      width: 48.5%;
      margin-bottom: 0;
    }
  }

  .form-btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    // .btn-blue,
    // .btn-blue-outline {
    //   width: 48%;
    // }
  }

  .password__show {
    position: absolute;
    top: 25px;
    right: 15px;

    cursor: pointer;
  }

  .form-select-caret-down,
  .form-input-calendar {
    position: absolute;
    top: 100%;
    right: 20px;
    z-index: 0;

    cursor: pointer;

    path {
      transition: all 0.15s ease-in-out;
    }
  }

  .form-select-caret-down {
    width: 15px;
    height: 15px;
  }

  .form-input-calendar {
    width: 20px;
    height: 20px;
  }

  .form-btn-dual-wrapper {
    display: flex;
    justify-content: space-around;

    .btn-d {
      width: 48.5%;
    }

    .btn-d-loading {
      width: 48.5%;
      height: 20%;
      margin-top: 0.6rem;
    }
  }

  .form-note {
    margin-bottom: 15px;
    width: 100%;

    font-size: 12px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 5;

    .react-datepicker__year-dropdown-container--select {
      margin: 8px 0;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      padding: 3px 10px;
    }
  }

  .react-tel-input {
    .form-input {
      &.form-control {
        padding: 25px 15px 15px 58px;
        width: 100%;

        background-color: $white-smoke;
        border: 0;

        &:focus {
          background-color: $white-smoke;
          border-color: 0;
          box-shadow: none;
        }
      }
    }

    .country-list {
      z-index: 999;

      width: 500px;
    }

    .selected-flag {
      padding: 0 0 0 17px;
    }

    .flag-dropdown {
      top: 11px;
    }
  }

  .text-bold {
    padding: 20px 0 15px;
    font-weight: 800;
  }

  .review-wrapper {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 15px;

    .review-holder {
      display: flex;
      margin-bottom: 7px;

      p {
        flex: 0 0 40%;
        justify-content: space-between;

        &:first-child {
          font-family: $font-gotham-bold;
        }
      }
    }
  }

  .form-wrapper {
    .react-tel-input {
      .form-control {
        padding: 18.5px 14px 18.5px 58px;
      }

      .flag-dropdown {
        top: 0;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  %label {
    font-size: 13px;
  }

  .form-wrapper {
    .form-col {
      display: block;

      .form-field {
        width: 100%;
      }
    }

    .form-group {
      display: block;
      margin-bottom: 0;

      .form-field {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    // .form-field {
    //   label {
    //     @extend %label;
    //   }
    // }

    .mixin-input-label {
      @extend %label;
    }

    .note {
      font-size: 13.5px;
    }
  }
}
