.modal {
  &-body {
    padding: 0!important;
  }

  // &-backdrop {
  //   background-color: $white!important;
  // }

  &-dark-opacity {
    .modal-backdrop {
      background-color: $white!important;
    }
  }

  &-content {
    position: relative;
    top: 60px;

    .close {
      position: absolute;
      top: 33px;
      right: 40px;
  
      width: 25px;
      height: 25px;
      
      cursor: pointer;
  
      &:before {
        content: '';
        position: absolute;
        top: 10px;
        
        width: 22px;
        height: 3px;
        
        background-color: $black;
        border-radius: 5px;
        
        transform: rotate(45deg);
      }
  
      &:after {
        @extend .close:before;
  
        transform: rotate(135deg);
      }
    }

    .back-btn {
      position: absolute;
      top: 40px;
      left: 40px;
      z-index: 1;
      
      width: 25px;
      height: 25px;
      
      cursor: pointer;
  
      &:before {
        content: '';
        
        position: absolute;
        top: 0px;
        
        width: 13px;
        height: 2px;
        
        background-color: $black;
  
        transform: rotate(135deg);
      }
  
      &:after {
        @extend .back-btn:before;
  
        top: 8px;
        
        transform: rotate(45deg);
      }
    }
  }
}