body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box; }

a,
button {
  cursor: pointer; }

a {
  text-decoration: none; }

.btn,
a,
button {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }
  .btn:active, .btn:focus, .btn:hover,
  a:active,
  a:focus,
  a:hover,
  button:active,
  button:focus,
  button:hover {
    text-decoration: none !important;
    outline: 0;
    box-shadow: none; }

.secret-page .back-btn {
  display: none; }

.hidden {
  opacity: 0;
  height: 1px; }

main {
  padding-top: 88px;
  overflow: hidden;
  min-height: 100vh; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0; }

html {
  height: 100%; }

html,
body {
  background-color: #f2f1f6 !important; }

/** Remove p margins **/
p {
  margin-bottom: 0 !important;
  padding: 0; }

/**
 * Basic styles for input fields
 */
input[type="checkbox"] {
  -webkit-appearance: checkbox; }

input,
select,
button,
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button,
  select::-webkit-outer-spin-button,
  select::-webkit-inner-spin-button,
  button::-webkit-outer-spin-button,
  button::-webkit-inner-spin-button,
  textarea::-webkit-outer-spin-button,
  textarea::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

ul {
  padding: 0;
  list-style-type: none; }

.section-notification {
  margin-bottom: 30px; }
  .section-notification .notification-text {
    margin: auto;
    padding: 15px;
    max-width: 500px;
    background-color: #fdf6f6;
    border-radius: 3px;
    border: 1px solid #f1c0bf;
    color: #dd6664;
    text-align: center; }

.custom-container {
  max-width: 1600px !important; }

.payment-transactions-button {
  max-width: 200px !important;
  margin-bottom: 10px; }

.custom-dashboard-container {
  margin-left: 440px !important; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 80px; }

.lds-ellipsis .dots {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #106397;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis .dots:nth-child(1) {
  left: calc(45% + 8px);
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis .dots:nth-child(2) {
  left: calc(45% + 8px);
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis .dots:nth-child(3) {
  left: calc(45% + 32px);
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis .dots:nth-child(4) {
  left: calc(45% + 56px);
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }

@media screen and (max-width: 575px) {
  main {
    padding-top: 69px; } }

@font-face {
  font-family: "Gotham";
  src: url(/static/media/gotham-book.5e185b40.eot);
  src: url(/static/media/gotham-book.5e185b40.eot?#iefix) format("embedded-opentype"), url(/static/media/gotham-book.5acb7203.woff) format("woff"), url(/static/media/gotham-book.32e50d54.ttf) format("truetype"), url(/static/media/gotham-book.ab96e42e.svg#gotham-book) format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url(/static/media/gotham-medium.72b8dccd.eot);
  src: url(/static/media/gotham-medium.72b8dccd.eot?#iefix) format("embedded-opentype"), url(/static/media/gotham-medium.fbfe0ab5.woff) format("woff"), url(/static/media/gotham-medium.7005cdde.ttf) format("truetype"), url(/static/media/gotham-medium.a9695d85.svg#gotham-medium) format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Gotham-Bold";
  src: url(/static/media/gotham-bold.a12319d5.eot);
  src: url(/static/media/gotham-bold.a12319d5.eot?#iefix) format("embedded-opentype"), url(/static/media/gotham-bold.f95835df.woff) format("woff"), url(/static/media/gotham-bold.4b060153.ttf) format("truetype"), url(/static/media/gotham-bold.abca61f6.svg#gotham-bold) format("svg");
  font-weight: 600;
  font-style: normal; }

body {
  color: #222;
  font: 200 100%/1.4 "Gotham" !important; }

.title-centered {
  text-align: center; }

.heading {
  font-size: 32px;
  font-family: "Gotham-Bold"; }

.text-blue {
  font-family: "Gotham-Bold";
  color: #106397; }

.text-black {
  color: #000000; }

.hover-text-black:active, .hover-text-black:focus, .hover-text-black:hover {
  color: #000000; }

@media screen and (max-width: 991px) {
  .title-centered {
    font-size: 28px; }
  .title-black {
    font-size: 16px; } }

@media screen and (max-width: 767px) {
  .title-centered {
    font-size: 22px; } }

.header {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  max-height: 90px;
  background: #073e64;
  background: linear-gradient(90deg, #073e64 0%, #0b6095 15%, #1680c0 30%, #3092c9 50%, #167fbe 70%, #0c649b 85%, #08456e 100%); }
  .header.logged-in .btn {
    font-weight: 100 !important; }
  .header.logged-in .navbar {
    justify-content: space-between !important;
    max-width: 100%;
    padding: .5rem 3rem; }
    .header.logged-in .navbar .btn-primary {
      color: white;
      background-color: transparent;
      padding: 0; }
  .header.logged-in .header-text-wrapper {
    display: flex; }
    .header.logged-in .header-text-wrapper .header-text {
      padding-right: 15px;
      color: #ffffff; }

.navbar {
  display: flex;
  justify-content: center !important;
  align-items: center;
  max-width: 1200px;
  margin: auto; }
  .navbar .btn-blue, .navbar .btn-blue-outline {
    padding: 10px 25px;
    max-width: 150px; }
  .navbar .btn-primary {
    color: #000000;
    background-color: #ffffff;
    border: none; }
  .navbar .logo-header {
    max-width: 270px;
    width: 100%;
    height: auto;
    padding: 5px 0; }
  .navbar .btn-holder {
    width: 25%; }

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: 90%;
  height: auto; }

.modal.logged-in .btn-holder {
  display: none; }

@media screen and (max-width: 575px) {
  .navbar .logo-header {
    max-width: 190px; } }

.section-breadcrumbs {
  padding-bottom: 25px; }
  .section-breadcrumbs.m-3 {
    margin: 15px 0; }
  .section-breadcrumbs .container {
    max-width: 100%; }
  .section-breadcrumbs .breadcrumbs-wrapper {
    display: flex;
    align-items: center; }
  .section-breadcrumbs .breadcrumbs-link {
    position: relative;
    padding-left: 25px;
    font-family: "Gotham";
    font-weight: 700;
    color: #000000;
    text-decoration: none; }
    .section-breadcrumbs .breadcrumbs-link.text-large {
      padding-left: 35px;
      font-size: 25px;
      font-weight: 500; }
    .section-breadcrumbs .breadcrumbs-link:active, .section-breadcrumbs .breadcrumbs-link:focus, .section-breadcrumbs .breadcrumbs-link:hover {
      color: #106397; }
  .section-breadcrumbs .btn-holder {
    margin-left: auto; }

.form-wrapper .mixin-focus-label, .form-wrapper .form-field label.label-fixed, .form-wrapper .form-input:valid ~ label, .form-wrapper .form-input:focus ~ label, .form-wrapper .form-input:disabled ~ label, .form-wrapper .form-input.not-required:focus ~ label {
  top: 10px;
  font-size: 13px;
  color: #878787;
  pointer-events: none; }

.form-wrapper .mixin-input-label, .form-wrapper .form-field label, .form-wrapper .form-input.not-required ~ label {
  top: 27px;
  font-size: 15px;
  color: #878787;
  cursor: auto; }

.form-wrapper .phoneInput {
  padding-right: 100; }

.form-wrapper .form-col {
  display: flex;
  justify-content: space-between; }
  .form-wrapper .form-col .form-field {
    width: 48%; }

.form-wrapper .form-field {
  position: relative;
  margin-bottom: 20px; }
  .form-wrapper .form-field .text-required {
    color: #ff0000e0; }
  .form-wrapper .form-field label {
    position: absolute;
    top: 27px;
    left: 15px;
    z-index: 1;
    display: -webkit-box;
    margin-bottom: 0;
    overflow: hidden;
    font-family: "Gotham-Bold";
    font-size: 15px;
    color: #878787;
    text-transform: uppercase;
    text-overflow: ellipsis;
    transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; }
  .form-wrapper .form-field.phone input[type="number"] {
    padding: 10px 70px; }
  .form-wrapper .form-field.calendar label, .form-wrapper .form-field.select label, .form-wrapper .form-field.phone label {
    top: 10px;
    width: calc(100% - 15px);
    font-size: 13px;
    color: #878787; }
  .form-wrapper .form-field:active .form-select-caret-down path,
  .form-wrapper .form-field:active .form-input-calendar path, .form-wrapper .form-field:focus .form-select-caret-down path,
  .form-wrapper .form-field:focus .form-input-calendar path, .form-wrapper .form-field:hover .form-select-caret-down path,
  .form-wrapper .form-field:hover .form-input-calendar path {
    fill: #286df9; }

.form-wrapper .form-input {
  z-index: 2;
  padding: 15px 15px 0;
  width: 100%;
  height: 70px;
  outline: 0;
  font-family: "Gotham";
  font-size: 15px;
  background: #f2f1f6;
  border: 1px solid #f5f8ff;
  border-radius: 5px; }
  .form-wrapper .form-input.pointer-disabled {
    pointer-events: none; }
  .form-wrapper .form-input::-webkit-input-placeholder {
    color: #000000;
    opacity: 0; }
  .form-wrapper .form-input::-ms-input-placeholder {
    color: #000000;
    opacity: 0; }
  .form-wrapper .form-input::placeholder {
    color: #000000;
    opacity: 0; }
  .form-wrapper .form-input.select {
    cursor: pointer; }
  .form-wrapper .form-input.checkbox_container {
    width: 50%; }

.form-wrapper .form-error {
  font-family: "Gotham";
  font-weight: 500;
  font-size: 14px;
  color: red; }

.form-wrapper .form-textarea {
  padding: 19px 0 15px; }
  .form-wrapper .form-textarea textarea {
    padding: 15px;
    height: 150px;
    resize: none; }
  .form-wrapper .form-textarea label {
    top: 0;
    left: 0;
    padding: 15px 15px 0;
    width: 100%;
    background-color: #f2f1f6; }
  .form-wrapper .form-textarea .form-input:valid ~ label,
  .form-wrapper .form-textarea .form-input:focus ~ label {
    top: 0; }

.form-wrapper .form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .form-wrapper .form-group.form-group-checkbox {
    justify-content: flex-start;
    padding: 15px 0; }
    .form-wrapper .form-group.form-group-checkbox .checkbox-wrapper {
      width: 50%; }
  .form-wrapper .form-group .form-field {
    width: 48.5%;
    margin-bottom: 0; }

.form-wrapper .form-btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.form-wrapper .password__show {
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer; }

.form-wrapper .form-select-caret-down,
.form-wrapper .form-input-calendar {
  position: absolute;
  top: 100%;
  right: 20px;
  z-index: 0;
  cursor: pointer; }
  .form-wrapper .form-select-caret-down path,
  .form-wrapper .form-input-calendar path {
    transition: all 0.15s ease-in-out; }

.form-wrapper .form-select-caret-down {
  width: 15px;
  height: 15px; }

.form-wrapper .form-input-calendar {
  width: 20px;
  height: 20px; }

.form-wrapper .form-btn-dual-wrapper {
  display: flex;
  justify-content: space-around; }
  .form-wrapper .form-btn-dual-wrapper .btn-d {
    width: 48.5%; }
  .form-wrapper .form-btn-dual-wrapper .btn-d-loading {
    width: 48.5%;
    height: 20%;
    margin-top: 0.6rem; }

.form-wrapper .form-note {
  margin-bottom: 15px;
  width: 100%;
  font-size: 12px; }

.form-wrapper .react-datepicker-wrapper {
  width: 100%; }

.form-wrapper .react-datepicker-popper {
  z-index: 5; }
  .form-wrapper .react-datepicker-popper .react-datepicker__year-dropdown-container--select {
    margin: 8px 0; }
  .form-wrapper .react-datepicker-popper .react-datepicker__month-select,
  .form-wrapper .react-datepicker-popper .react-datepicker__year-select {
    padding: 3px 10px; }

.form-wrapper .react-tel-input .form-input.form-control {
  padding: 25px 15px 15px 58px;
  width: 100%;
  background-color: #f2f1f6;
  border: 0; }
  .form-wrapper .react-tel-input .form-input.form-control:focus {
    background-color: #f2f1f6;
    border-color: 0;
    box-shadow: none; }

.form-wrapper .react-tel-input .country-list {
  z-index: 999;
  width: 500px; }

.form-wrapper .react-tel-input .selected-flag {
  padding: 0 0 0 17px; }

.form-wrapper .react-tel-input .flag-dropdown {
  top: 11px; }

.form-wrapper .text-bold {
  padding: 20px 0 15px;
  font-weight: 800; }

.form-wrapper .review-wrapper {
  margin-top: 25px;
  margin-bottom: 15px; }
  .form-wrapper .review-wrapper .review-holder {
    display: flex;
    margin-bottom: 7px; }
    .form-wrapper .review-wrapper .review-holder p {
      flex: 0 0 40%;
      justify-content: space-between; }
      .form-wrapper .review-wrapper .review-holder p:first-child {
        font-family: "Gotham-Bold"; }

.form-wrapper .form-wrapper .react-tel-input .form-control {
  padding: 18.5px 14px 18.5px 58px; }

.form-wrapper .form-wrapper .react-tel-input .flag-dropdown {
  top: 0; }

@media screen and (max-width: 575px) {
  .form-wrapper .mixin-input-label, .form-wrapper .form-field label, .form-wrapper .form-input.not-required ~ label {
    font-size: 13px; }
  .form-wrapper .form-col {
    display: block; }
    .form-wrapper .form-col .form-field {
      width: 100%; }
  .form-wrapper .form-group {
    display: block;
    margin-bottom: 0; }
    .form-wrapper .form-group .form-field {
      width: 100%;
      margin-bottom: 20px; }
  .form-wrapper .note {
    font-size: 13.5px; } }

.section-table {
  padding-bottom: 25px; }
  .section-table.table-solo {
    margin-top: 50px; }
  .section-table .table-button-holder {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px; }
    .section-table .table-button-holder .btn-csv-export,
    .section-table .table-button-holder .btn-pdf-export {
      width: 10% !important;
      min-width: 200px;
      text-align: center; }
  .section-table .ReactTable {
    border: none !important; }
    .section-table .ReactTable .rt-table {
      border-radius: 6px;
      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05); }
    .section-table .ReactTable .rt-tr .rt-th div {
      font-family: "Gotham";
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase; }
    .section-table .ReactTable .rt-thead {
      color: #ffffff;
      background-color: #106397; }
    .section-table .ReactTable .rt-td {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Gotham";
      font-size: 14px;
      text-align: center; }
    .section-table .ReactTable .rt-th,
    .section-table .ReactTable .rt-td {
      padding: 15px !important;
      white-space: normal !important; }
    .section-table .ReactTable .rt-td.table-point-plus:before {
      content: '+';
      position: relative;
      margin-right: 3px; }
    .section-table .ReactTable .rt-td.table-amount-peso:before {
      content: 'P';
      position: relative;
      margin-right: 3px; }
    .section-table .ReactTable .rt-td .table-status-active,
    .section-table .ReactTable .rt-td .table-status-inactive {
      position: relative; }
      .section-table .ReactTable .rt-td .table-status-active:after,
      .section-table .ReactTable .rt-td .table-status-inactive:after {
        content: '';
        position: absolute;
        top: calc(50% + 2px);
        right: -18px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        transform: translateY(-50%); }
    .section-table .ReactTable .rt-td .table-status-active:after {
      background-color: green; }
    .section-table .ReactTable .rt-td .table-status-inactive:after {
      background-color: red; }
    .section-table .ReactTable .rt-th {
      display: flex;
      align-items: center;
      justify-content: center; }
    .section-table .ReactTable .rt-tr-group {
      background-color: #ffffff; }
    .section-table .ReactTable .rt-resizable-header-content {
      text-align: left; }
    .section-table .ReactTable .button-wrapper {
      display: flex;
      flex-wrap: wrap; }
    .section-table .ReactTable .button-holder {
      width: auto; }
    .section-table .ReactTable .icon-link {
      display: block;
      padding: 0 6px;
      height: 100%; }
    .section-table .ReactTable .btn-icon {
      background: none;
      border: none;
      outline: none;
      cursor: pointer; }
      .section-table .ReactTable .btn-icon:active, .section-table .ReactTable .btn-icon:focus, .section-table .ReactTable .btn-icon:hover {
        outline: none; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px; }

.carousel-item {
  transition: transform 0.3s ease-in-out !important; }

.carousel-indicators {
  bottom: -50px !important;
  margin-right: 0 !important;
  margin-left: 0 !important; }
  .carousel-indicators li {
    background-color: #286df9 !important; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23286df9' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") !important; }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23286df9' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") !important; }

.carousel-control-prev,
.carousel-control-next {
  width: 7%; }

.sidebar-wrapper {
  box-shadow: 10px 10px 30px #e8e7eb;
  height: 100%;
  width: 20.5%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 88px; }
  .sidebar-wrapper::-webkit-scrollbar {
    width: 7px; }
  .sidebar-wrapper::-webkit-scrollbar-thumb {
    background: #c6cad3;
    border-radius: 10px; }
  .sidebar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e; }
  .sidebar-wrapper .sidebar-header {
    padding: 0 15px;
    margin-bottom: 23px;
    background-color: #f2f1f6;
    height: 165px;
    align-items: center;
    display: flex; }
    .sidebar-wrapper .sidebar-header.content {
      line-height: 1.25; }
  .sidebar-wrapper .img-qr {
    height: auto;
    max-width: 125px;
    width: 90px;
    transition: ease-in-out .3s all; }
    .sidebar-wrapper .img-qr:active, .sidebar-wrapper .img-qr:focus, .sidebar-wrapper .img-qr:hover {
      -webkit-filter: grayscale(1);
              filter: grayscale(1); }
  .sidebar-wrapper .member-name, .sidebar-wrapper .member-points {
    display: -webkit-box;
    font-family: "Gotham-Bold";
    font-size: 27px;
    color: #106397;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
  .sidebar-wrapper .member-points {
    font-size: 16px; }
  .sidebar-wrapper .sidebar-link {
    font-family: "Gotham-Bold";
    color: #106397; }
    .sidebar-wrapper .sidebar-link-holder {
      position: relative;
      margin-bottom: 2.5px;
      border-radius: 3px;
      padding: 13px 15px;
      align-items: center;
      cursor: pointer;
      transition: all .3s ease-in-out; }
      .sidebar-wrapper .sidebar-link-holder:active, .sidebar-wrapper .sidebar-link-holder:focus, .sidebar-wrapper .sidebar-link-holder:hover {
        background: #073e64;
        background: linear-gradient(90deg, #073e64 0%, #0b6095 50%); }
        .sidebar-wrapper .sidebar-link-holder:active .sidebar-link, .sidebar-wrapper .sidebar-link-holder:focus .sidebar-link, .sidebar-wrapper .sidebar-link-holder:hover .sidebar-link {
          color: #ffffff; }

@media screen and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-header {
    display: block;
    height: 200px;
    text-align: center; }
    .sidebar-wrapper .sidebar-header .lds-ellipsis .dots:nth-child(1) {
      left: calc(25% + 8px);
      -webkit-animation: lds-ellipsis1 0.6s infinite;
              animation: lds-ellipsis1 0.6s infinite; }
    .sidebar-wrapper .sidebar-header .lds-ellipsis .dots:nth-child(2) {
      left: calc(25% + 8px);
      -webkit-animation: lds-ellipsis2 0.6s infinite;
              animation: lds-ellipsis2 0.6s infinite; }
    .sidebar-wrapper .sidebar-header .lds-ellipsis .dots:nth-child(3) {
      left: calc(25% + 32px);
      -webkit-animation: lds-ellipsis2 0.6s infinite;
              animation: lds-ellipsis2 0.6s infinite; }
    .sidebar-wrapper .sidebar-header .lds-ellipsis .dots:nth-child(4) {
      left: calc(25% + 56px);
      -webkit-animation: lds-ellipsis3 0.6s infinite;
              animation: lds-ellipsis3 0.6s infinite; }
  .sidebar-wrapper .img-qr {
    width: 105px; } }

.btn-blue-no-width, .btn-blue, .btn-blue-outline, .btn-red, .btn-gray, .btn-white, .btn-yellow {
  padding: 15px 25px;
  font-family: "Gotham";
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer; }
  .btn-blue-no-width:active, .btn-blue:active, .btn-blue-outline:active, .btn-red:active, .btn-gray:active, .btn-white:active, .btn-yellow:active, .btn-blue-no-width:focus, .btn-blue:focus, .btn-blue-outline:focus, .btn-red:focus, .btn-gray:focus, .btn-white:focus, .btn-yellow:focus, .btn-blue-no-width:hover, .btn-blue:hover, .btn-blue-outline:hover, .btn-red:hover, .btn-gray:hover, .btn-white:hover, .btn-yellow:hover {
    color: #106397;
    background-color: transparent;
    transition: all 0.2s ease-in-out; }

.addSpace {
  margin-bottom: 10px; }

.rightSpace {
  margin-right: 3px; }

.btn-blue-no-width {
  background-color: #106397;
  border: 1px solid #106397;
  border-radius: 5px; }
  .btn-blue-no-width:disabled {
    pointer-events: none;
    -webkit-filter: opacity(0.7);
            filter: opacity(0.7); }
  .btn-blue-no-width.btn-sm {
    padding: 5px 10px; }

.btn-blue, .btn-blue-outline {
  width: 100%;
  background-color: #106397;
  border: 1px solid #106397;
  border-radius: 5px; }
  .btn-blue:disabled, .btn-blue-outline:disabled {
    pointer-events: none;
    -webkit-filter: opacity(0.7);
            filter: opacity(0.7); }
  .btn-blue.btn-sm, .btn-sm.btn-blue-outline {
    padding: 5px 10px; }

.btn-blue-outline {
  background-color: transparent;
  color: #106397; }
  .btn-blue-outline:active, .btn-blue-outline:focus, .btn-blue-outline:hover {
    color: #ffffff;
    background-color: #106397;
    transition: all 0.2s ease-in-out; }

.btn-red {
  width: 100%;
  background-color: #ff0000e0;
  border: 1px solid #ff0000e0;
  border-radius: 5px; }
  .btn-red:disabled {
    pointer-events: none;
    -webkit-filter: opacity(0.7);
            filter: opacity(0.7); }
  .btn-red:active, .btn-red:focus, .btn-red:hover {
    color: #ff0000e0; }
  .btn-red.btn-sm {
    padding: 5px 10px; }

.btn-gray {
  width: 100%;
  background-color: #c6cad3;
  border: 1px solid #c6cad3;
  border-radius: 5px; }
  .btn-gray:active, .btn-gray:focus, .btn-gray:hover {
    color: #ffffff;
    background-color: #106397; }
  .btn-gray.btn-sm {
    padding: 5px 10px;
    font-size: 12px; }

.disabled {
  pointer-events: none; }

.btn-gray-outline-gray {
  padding: 15px 25px;
  font-family: "Gotham";
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #106397;
  color: #106397;
  width: 100%;
  border-radius: 5px; }
  .btn-gray-outline-gray:disabled {
    pointer-events: none;
    -webkit-filter: opacity(0.7);
            filter: opacity(0.7); }
  .btn-gray-outline-gray.btn-sm {
    padding: 5px 10px; }
  .btn-gray-outline-gray.margin-top {
    margin-top: 0.5rem; }
  .btn-gray-outline-gray:active, .btn-gray-outline-gray:focus, .btn-gray-outline-gray:hover {
    color: #ffffff;
    background-color: #106397;
    transition: all 0.2s ease-in-out; }

.btn-white {
  color: #000000;
  border: none; }

.btn-yellow {
  padding: 5px 20px;
  font-size: 25px;
  color: #000000;
  background-color: #ffd200;
  border-radius: 5px; }
  .btn-yellow:active, .btn-yellow:focus, .btn-yellow:hover {
    background-color: #ffffff; }

.link-blue {
  font-size: 17px;
  text-decoration: none;
  color: #106397; }
  .link-blue:active, .link-blue:focus, .link-blue:hover {
    text-decoration: none;
    color: #286df9;
    transition: all 0.2s ease-in-out; }

.icon-actions {
  width: 20px;
  height: 20px; }
  .icon-actions path {
    transition: all 0.3s ease; }
  .icon-actions:active path, .icon-actions:focus path, .icon-actions:hover path {
    fill: #106397;
    stroke: #106397; }

.back-btn {
  position: absolute;
  top: calc(50% + 2px);
  left: 0;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  cursor: pointer; }
  .back-btn:before, .back-btn:after, .modal-content .back-btn:after {
    content: "";
    position: absolute;
    top: 7px;
    right: auto;
    right: initial;
    bottom: auto;
    bottom: initial;
    left: 5px;
    width: 13px;
    height: 2px;
    background-color: #106397;
    transform: rotate(135deg); }
  .back-btn:after {
    top: 15px;
    transform: rotate(45deg); }

.btn-transparent {
  padding: 0;
  border-radius: 0;
  text-decoration: underline;
  border: 0;
  outline: none; }
  .btn-transparent.red {
    color: red; }
  .btn-transparent.blue {
    color: blue; }

.export-dropdown .dropdown-toggle {
  padding: 15px 25px;
  font-family: "Gotham";
  text-transform: uppercase;
  color: #106397;
  background-color: transparent;
  border-color: #106397; }
  .export-dropdown .dropdown-toggle:active, .export-dropdown .dropdown-toggle:focus, .export-dropdown .dropdown-toggle:hover {
    color: #106397;
    background-color: transparent;
    outline: none;
    box-shadow: none; }
  .export-dropdown .dropdown-toggle:hover {
    color: #ffffff;
    background-color: #106397; }

.export-dropdown .btn-primary:not(:disabled):not(.disabled):active,
.export-dropdown .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #106397;
  border-color: #106397;
  outline: none;
  box-shadow: none; }
  .export-dropdown .btn-primary:not(:disabled):not(.disabled):active:active, .export-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus, .export-dropdown .btn-primary:not(:disabled):not(.disabled):active:hover,
  .export-dropdown .show > .btn-primary.dropdown-toggle:active,
  .export-dropdown .show > .btn-primary.dropdown-toggle:focus,
  .export-dropdown .show > .btn-primary.dropdown-toggle:hover {
    outline: none;
    box-shadow: none; }

.export-option {
  padding: 5px 15px;
  width: 100%; }
  .export-option:hover {
    background-color: #106397; }
    .export-option:hover * {
      color: #ffffff; }
  .export-option > * {
    display: block;
    width: 100%;
    cursor: pointer; }

@media screen and (max-width: 575px) {
  .btn-blue-no-width, .btn-blue, .btn-blue-outline, .btn-red, .btn-gray, .btn-white, .btn-yellow {
    padding: 13px 25px;
    font-size: 14px; } }

@media screen and (max-width: 991px) {
  .btn-yellow {
    padding: 3px 14px;
    font-size: 20px; }
  .link-blue {
    font-size: 16px; } }

.card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: auto;
  max-width: 560px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 10px 10px 30px #e8e7eb; }
  .card-wrapper.no-margin {
    margin: 0; }
  .card-wrapper.no-shadow {
    box-shadow: none; }
  .card-wrapper.has-back-button .title {
    margin-left: 0;
    padding-left: 30px; }
  .card-wrapper a {
    color: #000000; }
    .card-wrapper a:active, .card-wrapper a:focus, .card-wrapper a:hover {
      color: #106397; }
  .card-wrapper h2 {
    position: relative;
    margin-bottom: 25px;
    font-family: "Gotham-Bold"; }
  .card-wrapper .form-wrapper {
    display: flex;
    flex-direction: column;
    margin: 15px 0; }
  .card-wrapper .signup-option {
    padding-bottom: 20px;
    font-size: 17px;
    text-align: center; }
    .card-wrapper .signup-option span {
      border-bottom: 1px solid #000000;
      cursor: pointer; }
      .card-wrapper .signup-option span:active, .card-wrapper .signup-option span:focus, .card-wrapper .signup-option span:hover {
        color: #286df9;
        border-color: #286df9; }
  .card-wrapper .divider:after {
    content: '';
    position: absolute;
    top: 152px;
    right: 0;
    left: 0;
    z-index: 0;
    margin: auto;
    width: 84%;
    height: 1px;
    background-color: #f2f1f6; }
  .card-wrapper .divider + p {
    position: relative;
    z-index: 0;
    padding-bottom: 25px;
    margin: auto;
    width: 35px;
    color: #7e7e7e;
    font-size: 16px;
    text-align: center;
    background: #ffffff; }
  .card-wrapper .cta {
    margin-top: 25px;
    font-size: 15px;
    text-align: center; }
    .card-wrapper .cta span {
      color: #000000;
      border-bottom: 0.5px solid #909090;
      cursor: pointer;
      transition: ease-in-out .3s all; }
      .card-wrapper .cta span:active, .card-wrapper .cta span:focus, .card-wrapper .cta span:hover {
        color: #106397;
        border-color: #106397; }
  .card-wrapper .message {
    padding-bottom: 25px;
    margin: auto;
    max-width: 360px;
    font-size: 15px;
    color: #7e7e7e;
    text-align: center; }
  .card-wrapper .success-icon {
    padding-bottom: 30px;
    margin: auto;
    width: 100px;
    height: 125px; }
  .card-wrapper form .remember-password {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px; }
    .card-wrapper form .remember-password p {
      font-size: 15px; }
  .card-wrapper form .checkbox-wrapper {
    position: relative;
    display: flex;
    align-items: center; }
    .card-wrapper form .checkbox-wrapper input[type="checkbox"] {
      display: none; }
      .card-wrapper form .checkbox-wrapper input[type="checkbox"]:checked + label:after {
        opacity: 1; }
    .card-wrapper form .checkbox-wrapper label {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .card-wrapper form .checkbox-wrapper label:before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0;
        padding-left: 18px;
        width: 1px;
        min-height: 18px;
        border: 1px solid #c6cad3;
        border-radius: 5px; }
      .card-wrapper form .checkbox-wrapper label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 4.5px;
        width: 11px;
        height: 7px;
        background: transparent;
        border: 3px solid #106397;
        border-top: none;
        border-right: none;
        opacity: 0;
        transform: rotate(-45deg); }
      .card-wrapper form .checkbox-wrapper label:hover:after {
        opacity: 0; }
  .card-wrapper form .form-field.phone label {
    top: 10px;
    left: 18px;
    font-size: 13px;
    color: #7e7e7e;
    pointer-events: none; }
  .card-wrapper .btn-blue, .card-wrapper .btn-blue-outline,
  .card-wrapper .btn-gray {
    margin-top: 10px; }

@media screen and (max-width: 575px) {
  .card-wrapper {
    padding: 25px 15px; }
    .card-wrapper h2 {
      margin-bottom: 0;
      font-size: 27px; }
    .card-wrapper .cta {
      font-size: 13px; } }

.dashboard-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .dashboard-wrapper .dashboard-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 23.5%;
    margin-bottom: 40px;
    margin-right: 25px;
    text-decoration: none;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 10px 10px 30px #e8e7eb;
    cursor: pointer; }
    .dashboard-wrapper .dashboard-item:nth-child(4n+4) {
      margin-right: 0; }
    .dashboard-wrapper .dashboard-item:active, .dashboard-wrapper .dashboard-item:focus, .dashboard-wrapper .dashboard-item:hover {
      background-color: #d2d2d2; }
  .dashboard-wrapper .icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 0;
    width: 100%;
    height: 100%;
    margin-bottom: 5px; }
    .dashboard-wrapper .icon-holder .icon-img {
      width: 100px;
      height: 130px;
      object-fit: contain; }
  .dashboard-wrapper .title-holder {
    padding: 0 15px 30px;
    width: calc(100% + 8px);
    text-align: center; }
    .dashboard-wrapper .title-holder .title-item {
      font-family: "Gotham-Bold";
      font-size: 17px;
      color: #222;
      line-height: 1; }
  .dashboard-wrapper.featured-deals .icon-holder {
    padding: 0;
    margin-bottom: 0; }
    .dashboard-wrapper.featured-deals .icon-holder .icon-img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .dashboard-wrapper.featured-deals .content-holder {
    position: absolute;
    z-index: 999; }
  .dashboard-wrapper.featured-deals .dashboard-item {
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    height: 220px;
    cursor: auto;
    cursor: initial; }
    .dashboard-wrapper.featured-deals .dashboard-item:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(70, 72, 82, 0.3); }
  .dashboard-wrapper.featured-deals .dashboard-swiper .deal-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 7px !important;
    font-size: 25px; }
  .dashboard-wrapper.featured-deals .dashboard-swiper .deal-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 18px;
    line-height: 22px; }
  .dashboard-wrapper.featured-deals .dashboard-swiper .swiper-content {
    max-width: 100%;
    padding: 35px 15px; }

@media (min-width: 992px) and (max-width: 1299px) {
  .dashboard-wrapper .dashboard-item {
    flex-basis: 25%; }
    .dashboard-wrapper .dashboard-item:nth-child(3n+3) {
      margin-right: 0; } }

@media (min-width: 1200px) and (max-width: 1299px) {
  .dashboard-wrapper .dashboard-item:nth-child(4n+4) {
    margin-right: 25px; }
  .dashboard-wrapper .dashboard-item:last-child {
    margin-right: 0; } }

@media screen and (max-width: 1199px) {
  .dashboard-wrapper {
    padding: 30px 0; }
    .dashboard-wrapper .dashboard-item {
      margin-right: 3.5%; }
      .dashboard-wrapper .dashboard-item:nth-child(4n+4) {
        margin-right: 3.5%; }
      .dashboard-wrapper .dashboard-item:last-child {
        margin-right: 0; } }

@media screen and (max-width: 991px) {
  .dashboard-wrapper .dashboard-item {
    flex-basis: 35%;
    margin-right: 8%; }
    .dashboard-wrapper .dashboard-item:nth-child(2n+2) {
      margin-right: 0; } }

@media screen and (max-width: 767px) {
  .dashboard-wrapper .dashboard-item {
    flex-basis: 47%;
    margin-right: 6%;
    margin-bottom: 20px; }
    .dashboard-wrapper .dashboard-item:nth-child(3n+3) {
      margin-right: 6%; }
  .dashboard-wrapper .icon-holder .icon-img {
    width: 70px; }
  .dashboard-wrapper .title-holder {
    padding: 15px 3px; }
    .dashboard-wrapper .title-holder .title-item {
      font-size: 13px; } }

@media screen and (max-width: 575px) {
  .dashboard-wrapper .dashboard-item {
    flex-basis: 48%;
    margin-right: 4%; }
    .dashboard-wrapper .dashboard-item:nth-child(2n+2) {
      margin-right: 0; }
    .dashboard-wrapper .dashboard-item:nth-child(3n+3) {
      margin-right: 4%; }
  .dashboard-wrapper .icon-holder {
    padding: 40px 30px; }
    .dashboard-wrapper .icon-holder .icon-img {
      width: 55px; }
  .dashboard-wrapper .title-holder {
    padding: 10px 3px; }
    .dashboard-wrapper .title-holder .title-item {
      font-size: 10px; } }

.modal-body {
  padding: 0 !important; }

.modal-dark-opacity .modal-backdrop {
  background-color: #ffffff !important; }

.modal-content {
  position: relative;
  top: 60px; }
  .modal-content .close {
    position: absolute;
    top: 33px;
    right: 40px;
    width: 25px;
    height: 25px;
    cursor: pointer; }
    .modal-content .close:before, .modal-content .close:after {
      content: '';
      position: absolute;
      top: 10px;
      width: 22px;
      height: 3px;
      background-color: #000000;
      border-radius: 5px;
      transform: rotate(45deg); }
    .modal-content .close:after {
      transform: rotate(135deg); }
  .modal-content .back-btn {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 1;
    width: 25px;
    height: 25px;
    cursor: pointer; }
    .modal-content .back-btn:before, .modal-content .back-btn:after {
      content: '';
      position: absolute;
      top: 0px;
      width: 13px;
      height: 2px;
      background-color: #000000;
      transform: rotate(135deg); }
    .modal-content .back-btn:after {
      top: 8px;
      transform: rotate(45deg); }

.card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: auto;
  max-width: 560px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 10px 10px 30px #e8e7eb; }
  .card-wrapper.no-margin {
    margin: 0; }
  .card-wrapper.no-shadow {
    box-shadow: none; }
  .card-wrapper.has-back-button .title {
    margin-left: 0;
    padding-left: 30px; }
  .card-wrapper a {
    color: #000000; }
    .card-wrapper a:active, .card-wrapper a:focus, .card-wrapper a:hover {
      color: #106397; }
  .card-wrapper h2 {
    position: relative;
    margin-bottom: 25px;
    font-family: "Gotham-Bold"; }
  .card-wrapper .form-wrapper {
    display: flex;
    flex-direction: column;
    margin: 15px 0; }
  .card-wrapper .signup-option {
    padding-bottom: 20px;
    font-size: 17px;
    text-align: center; }
    .card-wrapper .signup-option span {
      border-bottom: 1px solid #000000;
      cursor: pointer; }
      .card-wrapper .signup-option span:active, .card-wrapper .signup-option span:focus, .card-wrapper .signup-option span:hover {
        color: #286df9;
        border-color: #286df9; }
  .card-wrapper .divider:after {
    content: '';
    position: absolute;
    top: 152px;
    right: 0;
    left: 0;
    z-index: 0;
    margin: auto;
    width: 84%;
    height: 1px;
    background-color: #f2f1f6; }
  .card-wrapper .divider + p {
    position: relative;
    z-index: 0;
    padding-bottom: 25px;
    margin: auto;
    width: 35px;
    color: #7e7e7e;
    font-size: 16px;
    text-align: center;
    background: #ffffff; }
  .card-wrapper .cta {
    margin-top: 25px;
    font-size: 15px;
    text-align: center; }
    .card-wrapper .cta span {
      color: #000000;
      border-bottom: 0.5px solid #909090;
      cursor: pointer;
      transition: ease-in-out .3s all; }
      .card-wrapper .cta span:active, .card-wrapper .cta span:focus, .card-wrapper .cta span:hover {
        color: #106397;
        border-color: #106397; }
  .card-wrapper .message {
    padding-bottom: 25px;
    margin: auto;
    max-width: 360px;
    font-size: 15px;
    color: #7e7e7e;
    text-align: center; }
  .card-wrapper .success-icon {
    padding-bottom: 30px;
    margin: auto;
    width: 100px;
    height: 125px; }
  .card-wrapper form .remember-password {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px; }
    .card-wrapper form .remember-password p {
      font-size: 15px; }
  .card-wrapper form .checkbox-wrapper {
    position: relative;
    display: flex;
    align-items: center; }
    .card-wrapper form .checkbox-wrapper input[type="checkbox"] {
      display: none; }
      .card-wrapper form .checkbox-wrapper input[type="checkbox"]:checked + label:after {
        opacity: 1; }
    .card-wrapper form .checkbox-wrapper label {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .card-wrapper form .checkbox-wrapper label:before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0;
        padding-left: 18px;
        width: 1px;
        min-height: 18px;
        border: 1px solid #c6cad3;
        border-radius: 5px; }
      .card-wrapper form .checkbox-wrapper label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 4.5px;
        width: 11px;
        height: 7px;
        background: transparent;
        border: 3px solid #106397;
        border-top: none;
        border-right: none;
        opacity: 0;
        transform: rotate(-45deg); }
      .card-wrapper form .checkbox-wrapper label:hover:after {
        opacity: 0; }
  .card-wrapper form .form-field.phone label {
    top: 10px;
    left: 18px;
    font-size: 13px;
    color: #7e7e7e;
    pointer-events: none; }
  .card-wrapper .btn-blue, .card-wrapper .btn-blue-outline,
  .card-wrapper .btn-gray {
    margin-top: 10px; }

@media screen and (max-width: 575px) {
  .card-wrapper {
    padding: 25px 15px; }
    .card-wrapper h2 {
      margin-bottom: 0;
      font-size: 27px; }
    .card-wrapper .cta {
      font-size: 13px; } }

.section-filter .accordion {
  border: none; }
  .section-filter .accordion__panel {
    padding: 10px 0; }
  .section-filter .accordion__button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    font-family: "Gotham-Bold";
    text-align: left;
    color: #000000;
    background-color: transparent;
    outline: 0;
    transition: ease-in-out 0.3s all; }
    .section-filter .accordion__button:before {
      width: 8px;
      height: 8px;
      margin-left: 12px; }
    .section-filter .accordion__button:active, .section-filter .accordion__button:focus, .section-filter .accordion__button:hover {
      color: #106397;
      background-color: transparent; }

.section-filter .filter-heading {
  padding-bottom: 30px;
  font-family: "Gotham-Bold";
  color: #000000; }

.section-filter .filter-wrapper {
  display: flex;
  align-items: flex-end; }

.section-filter .form-wrapper {
  padding: 15px;
  align-items: center;
  justify-content: space-between; }
  .section-filter .form-wrapper.transactions .filter-wrapper {
    max-width: 100%; }
  .section-filter .form-wrapper.transactions .group-wrapper .form-group {
    margin-right: 70px; }
  .section-filter .form-wrapper.transactions .group-wrapper.date {
    width: 100%; }
    .section-filter .form-wrapper.transactions .group-wrapper.date .form-group {
      margin-right: 25px; }
  .section-filter .form-wrapper.transactions .group-wrapper.name .form-field {
    margin-right: 25px; }
  .section-filter .form-wrapper.transactions .group-wrapper.name .single-input {
    width: 300px;
    margin-right: 25px; }
  .section-filter .form-wrapper.transactions .group-wrapper.amount {
    width: 100%; }
    .section-filter .form-wrapper.transactions .group-wrapper.amount .form-col {
      margin-right: 0; }
  .section-filter .form-wrapper.transactions .single-input {
    width: 370px;
    margin-right: 70px; }
  .section-filter .form-wrapper.transactions .form-col {
    margin-right: 70px; }
    .section-filter .form-wrapper.transactions .form-col .form-field:first-child {
      margin-right: 15px; }
  .section-filter .form-wrapper.statement .filter-wrapper {
    max-width: 940px; }
  .section-filter .form-wrapper.statement .group-wrapper.name {
    margin: 0 25px; }
  .section-filter .form-wrapper .form-group {
    justify-content: flex-start;
    margin-bottom: 0; }
  .section-filter .form-wrapper .form-field {
    margin-bottom: 0; }
  .section-filter .form-wrapper .calendar {
    width: 45%; }
    .section-filter .form-wrapper .calendar:first-child {
      margin-right: 15px; }
    .section-filter .form-wrapper .calendar .label-calendar {
      position: absolute;
      font-size: 19px; }
      .section-filter .form-wrapper .calendar .label-calendar path {
        fill: #106397; }
    .section-filter .form-wrapper .calendar .input-label {
      position: absolute;
      top: -25px; }
  .section-filter .form-wrapper .width-full {
    width: 100%; }
  .section-filter .form-wrapper .name-field {
    width: 300px;
    max-width: 100%; }
  .section-filter .form-wrapper .form-input {
    height: 50px;
    padding: 15px;
    font-family: "Gotham-Bold";
    color: #106397;
    background-color: #ffffff; }
  .section-filter .form-wrapper .btn-blue, .section-filter .form-wrapper .btn-blue-outline {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-left: auto;
    padding: 10px 15px;
    height: 50px;
    width: 15% !important; }

@media screen and (max-width: 1199px) {
  .section-filter .filter-wrapper {
    flex-direction: column;
    align-items: flex-start; }
  .section-filter .group-wrapper {
    width: 50%;
    margin-bottom: 20px; }
    .section-filter .group-wrapper:not(:first-child) .filter-heading {
      padding-bottom: 0; }
  .section-filter .form-wrapper.transactions .calendar {
    width: 48%; }
  .section-filter .form-wrapper.transactions .form-group {
    margin-right: 0; }
  .section-filter .form-wrapper.transactions .form-col {
    margin-right: 0; }
  .section-filter .form-wrapper.transactions .single-input {
    width: 100%;
    margin-right: 0; }
  .section-filter .form-wrapper.statement .filter-wrapper {
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap; }
  .section-filter .form-wrapper.statement .group-wrapper {
    padding-bottom: 20px; }
    .section-filter .form-wrapper.statement .group-wrapper.name {
      margin: 0; }
    .section-filter .form-wrapper.statement .group-wrapper:not(:first-child) .filter-heading {
      padding-bottom: 30px; } }

.confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); }
  .confirm-overlay.show-modal {
    display: block; }
  .confirm-overlay .confirm-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 8px;
    transform: translate(-50%, -50%); }
  .confirm-overlay .confirm-title {
    padding-bottom: 15px;
    border-bottom: 1px solid #c6cad3; }
  .confirm-overlay .confirm-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 15px; }
    .confirm-overlay .confirm-footer .btn-cancel {
      color: #286df9;
      background-color: #ffffff; }
      .confirm-overlay .confirm-footer .btn-cancel:hover {
        color: #ffffff;
        background-color: #286df9; }
    .confirm-overlay .confirm-footer .btn-confirm {
      margin-left: 25px; }

.transaction-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  margin: 0 auto; }
  .transaction-buttons .btn-blue, .transaction-buttons .btn-blue-outline {
    margin-top: 2rem;
    text-align: center;
    font-size: 16px;
    font-weight: 400; }

.tab-member-account {
  max-width: 650px;
  margin: 0 auto; }
  .tab-member-account .infinity-card-img {
    margin: 0 auto; }
  .tab-member-account.member-transaction {
    max-width: 100%; }
  .tab-member-account .heading {
    text-align: center;
    padding-bottom: 25px; }
  .tab-member-account .member-card {
    position: relative;
    width: 500px;
    height: 285px;
    padding: 20px;
    margin: 0 auto;
    background-size: contain; }
    .tab-member-account .member-card.basic {
      background-image: url(/static/media/card-basic.d48a6998.jpg); }
    .tab-member-account .member-card.silver {
      background-image: url(/static/media/card-silver.ab32bcd4.jpg); }
    .tab-member-account .member-card.gold {
      background-image: url(/static/media/card-gold.aa784d93.jpg); }
    .tab-member-account .member-card.platinum {
      background-image: url(/static/media/card-platinum.e7050e9b.jpg); }
    .tab-member-account .member-card .member-card-info {
      position: absolute;
      bottom: 20px; }
    .tab-member-account .member-card .member-name, .tab-member-account .member-card .sidebar-wrapper .member-points, .sidebar-wrapper .tab-member-account .member-card .member-points {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase; }
    .tab-member-account .member-card .member-card-number {
      margin: 0;
      font-size: 35px;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 5px; }
    .tab-member-account .member-card .member-bottom-info {
      display: flex;
      justify-content: space-between; }
    .tab-member-account .member-card .member-expiration {
      color: #ffffff;
      font-size: 13px; }
    .tab-member-account .member-card .member-reference {
      color: #ffffff;
      font-size: 13px; }
    .tab-member-account .member-card .tier-logo {
      position: absolute;
      right: 7.5%;
      bottom: 0;
      width: 75px;
      height: 75px;
      background-image: url(/static/media/card-tier.45219561.jpg);
      background-size: cover; }
      .tab-member-account .member-card .tier-logo .member-tier {
        position: absolute;
        top: 55%;
        width: 100%;
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        transform: translateY(-50%); }
  .tab-member-account .info-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem; }
    .tab-member-account .info-card .points-card,
    .tab-member-account .info-card .referrals-card,
    .tab-member-account .info-card .expiration-card {
      padding: 15px;
      width: 31%;
      background-color: #ffffff;
      border-radius: 7px; }
      .tab-member-account .info-card .points-card p,
      .tab-member-account .info-card .referrals-card p,
      .tab-member-account .info-card .expiration-card p {
        color: #c6cad3;
        font-size: 12px; }
      .tab-member-account .info-card .points-card h4,
      .tab-member-account .info-card .referrals-card h4,
      .tab-member-account .info-card .expiration-card h4 {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 18px; }
    .tab-member-account .info-card .membership-card,
    .tab-member-account .info-card .tier-card,
    .tab-member-account .info-card .category-card {
      padding: 15px;
      margin-top: 1rem;
      width: 100%;
      background-color: #ffffff;
      border-radius: 7px; }
      .tab-member-account .info-card .membership-card h4,
      .tab-member-account .info-card .tier-card h4,
      .tab-member-account .info-card .category-card h4 {
        margin: 0;
        font-size: 18px; }
  .tab-member-account .text-blue {
    display: flex;
    flex: 100% 1;
    justify-content: center;
    padding: 15px 0;
    cursor: pointer; }

.tab-personal-data .personal-data-title {
  text-align: center; }

.tab-personal-data .personal-data-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px; }

.tab-personal-data .personal-data {
  margin-bottom: 1rem;
  width: 100%; }
  .tab-personal-data .personal-data:last-child {
    margin-bottom: 0; }
  .tab-personal-data .personal-data.gender, .tab-personal-data .personal-data.title {
    width: 48%; }
  .tab-personal-data .personal-data.additional h4 {
    text-align: center; }
  .tab-personal-data .personal-data p {
    font-weight: 500;
    color: #7e7e7e; }

.custom-pagination {
  display: flex;
  margin-top: 1rem; }
  .custom-pagination li {
    padding: 7px;
    margin: 0 1.5px;
    min-width: 35px;
    font-family: "Gotham-Bold";
    text-align: center;
    color: #7e7e7e;
    background-color: #ffffff;
    border: 1px solid #c6cad3;
    transition: ease-in-out .3s all; }
    .custom-pagination li:active, .custom-pagination li:focus, .custom-pagination li:hover {
      color: #ffffff;
      background-color: #106397;
      cursor: pointer; }
    .custom-pagination li:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .custom-pagination li:last-of-type {
      border-right: 1px solid #c6cad3;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .custom-pagination li.active {
      color: #ffffff;
      background-color: #106397;
      cursor: default; }
  .custom-pagination .total-docs-label {
    padding: 7px;
    font-family: "Gotham-Bold";
    color: #7e7e7e; }

.infinity-card-img {
  position: relative;
  max-width: 490px; }
  .infinity-card-img .card-logo {
    position: absolute;
    top: 18px;
    left: 15px;
    width: 270px;
    height: auto;
    object-fit: contain; }
  .infinity-card-img .card-details {
    position: absolute;
    width: 100%;
    padding: 0 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    bottom: 0;
    color: #ffffff; }
  .infinity-card-img .img-card {
    max-width: 490px;
    object-fit: contain;
    border-radius: 7px;
    box-shadow: 10px 10px 30px #e8e7eb; }
  .infinity-card-img .tier-wrapper {
    margin-bottom: 6px;
    position: relative;
    right: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px; }
    .infinity-card-img .tier-wrapper .card-tier {
      position: absolute;
      right: 0;
      bottom: 0;
      object-fit: contain;
      width: 68px;
      height: 68px; }
    .infinity-card-img .tier-wrapper .tier-text {
      z-index: 999;
      font-family: "Gotham-Bold";
      font-size: 25px; }
  .infinity-card-img .card-bottom-text {
    font-size: 11px; }
  .infinity-card-img .member-bottom-info {
    display: flex;
    justify-content: space-between; }
  .infinity-card-img .member-name, .infinity-card-img .sidebar-wrapper .member-points, .sidebar-wrapper .infinity-card-img .member-points {
    font-family: "Gotham-Bold";
    font-size: 17px;
    text-transform: uppercase; }
  .infinity-card-img .member-card-info {
    padding-bottom: 15px;
    line-height: 29px; }
  .infinity-card-img .member-card-number {
    font-family: "Gotham-Bold";
    font-size: 31px;
    letter-spacing: 4px; }

.dashboard-swiper {
  margin-bottom: 30px; }
  .dashboard-swiper .swiper-slide {
    border: none;
    padding: 10px 25px;
    height: 420px;
    overflow: hidden; }
  .dashboard-swiper .swiper-container {
    max-height: 465px;
    height: 465px; }
    .dashboard-swiper .swiper-container > .swiper-pagination-bullets {
      bottom: 0; }
      .dashboard-swiper .swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        margin: 0 8px; }
  .dashboard-swiper .top-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .dashboard-swiper .top-logo {
    width: 55px;
    background-color: #ffffff;
    object-fit: contain;
    margin-top: -15px;
    padding: 10px 5px 5px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px; }
  .dashboard-swiper .top-buttons {
    display: flex;
    align-items: center;
    width: 80px;
    justify-content: space-between; }
  .dashboard-swiper .top-icons {
    font-size: 35px;
    color: #ffffff;
    cursor: pointer;
    transition: ease-in-out .2s all; }
    .dashboard-swiper .top-icons:active, .dashboard-swiper .top-icons:focus, .dashboard-swiper .top-icons:hover {
      color: #106397; }
  .dashboard-swiper .swiper-content {
    padding-top: 25px;
    max-width: 70%;
    line-height: 1; }
  .dashboard-swiper .deal-title {
    font-family: "Gotham-Bold";
    font-size: 65px;
    text-transform: uppercase;
    color: #ffffff; }
  .dashboard-swiper .deal-description {
    font-size: 25px;
    color: #ffffff; }
  .dashboard-swiper .deal-link {
    position: absolute;
    background-color: #106397;
    border: 1.5px solid #106397;
    color: #ffffff;
    padding: 7px;
    margin-top: 15px;
    transition: ease-in-out .3s all;
    cursor: pointer; }
    .dashboard-swiper .deal-link:active, .dashboard-swiper .deal-link:focus, .dashboard-swiper .deal-link:hover {
      background-color: transparent; }
  .dashboard-swiper .deal-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -5;
    width: 100%;
    max-height: 420px;
    object-fit: cover; }
  .dashboard-swiper .navigations-wrapper {
    position: relative;
    top: -20px;
    z-index: 9999; }
    .dashboard-swiper .navigations-wrapper .back-btn {
      right: auto;
      left: 0; }
    .dashboard-swiper .navigations-wrapper .next-btn {
      right: 0;
      left: auto;
      transform: rotate(180deg) translateY(60%); }

@media screen and (max-width: 1199px) {
  .dashboard-swiper .swiper-container {
    height: 425px; } }

.edit-modal h2 {
  display: none; }

.edit-modal .heading {
  font-size: 20px; }
  .edit-modal .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px; }
  .edit-modal .heading-close {
    font-size: 25px;
    cursor: pointer;
    transition: ease-in-out .3s all; }
    .edit-modal .heading-close:active, .edit-modal .heading-close:focus, .edit-modal .heading-close:hover {
      color: #106397; }

.featured-button-holder {
  margin: 0 15px; }
  .featured-button-holder .switch {
    margin: 0 8px; }
  .featured-button-holder .btn-light {
    color: #212529;
    background-color: #e2e6ea; }
  .featured-button-holder .switch.off .switch-group {
    left: -50%; }
  .featured-button-holder .switch.off .switch-handle.btn-light {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .featured-button-holder .switch-on,
  .featured-button-holder .switch-off {
    display: none; }
  .featured-button-holder .switch-group {
    width: 100%; }
  .featured-button-holder .switch.btn {
    min-width: 40px;
    min-height: 20px;
    border-radius: 50px; }
  .featured-button-holder .switch-handle {
    top: -4.5px;
    left: 10px;
    padding: 7px 7px;
    width: 2px;
    height: 13px;
    border-radius: 100%;
    cursor: pointer; }

.radio.form-input input[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio; }

.radio-wrapper {
  justify-content: space-between; }
  .radio-wrapper.have-display {
    display: flex;
    max-width: 40%; }
  .radio-wrapper.terms-privacy {
    display: block;
    max-width: unset; }
    .radio-wrapper.terms-privacy .link {
      color: #106397; }
      .radio-wrapper.terms-privacy .link:active, .radio-wrapper.terms-privacy .link:focus, .radio-wrapper.terms-privacy .link:hover {
        color: #000000; }
  .radio-wrapper .form-field {
    display: flex;
    align-items: center;
    align-content: center; }
    .radio-wrapper .form-field label {
      top: 0;
      left: 0;
      width: 22px; }
    .radio-wrapper .form-field .radio-text {
      padding-left: 30px;
      font-family: "Gotham-Bold";
      color: #909090; }
  .radio-wrapper .radio {
    position: relative; }
  .radio-wrapper .radio input {
    position: relative;
    opacity: 0; }
  .radio-wrapper .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border: 2px solid #eee;
    border-radius: 5px;
    cursor: pointer; }
  .radio-wrapper .radio:hover input ~ .checkmark {
    background-color: #ccc; }
  .radio-wrapper .radio input:checked ~ .checkmark {
    background-color: #106397; }
  .radio-wrapper .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .radio-wrapper .radio input:checked ~ .checkmark:after {
    display: block; }
  .radio-wrapper .radio .checkmark:after {
    top: 4px;
    left: 3.5px;
    width: 11px;
    height: 7px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg); }

@media screen and (max-width: 575px) {
  .radio-wrapper {
    max-width: 70%; } }

.notif-badge {
  position: absolute;
  top: 50%;
  right: 20px;
  padding: 0 10px;
  color: #ffffff;
  text-align: center;
  background-color: #ff0000e0;
  border-radius: 10px;
  transform: translateY(-50%); }

.signup-steps .step-title {
  margin-top: 1rem; }

.signup-steps .step-list {
  margin-top: 1rem;
  padding-left: 1rem;
  list-style-type: disc; }

.signup-steps .email {
  color: #106397;
  font-weight: 500; }
  .signup-steps .email:active, .signup-steps .email:focus, .signup-steps .email:hover {
    color: #000000; }

.card-design {
  max-width: 220px;
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.card-container {
  background-color: whitesmoke; }

.card-title {
  text-align: center;
  color: red; }

.padded-body {
  padding: 20px; }

.cookie-consent {
  background-color: #106397; }

.modal-3ds {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: none;
  width: 600px;
  height: 70%;
  transform: translate(-50%, -50%); }
  .modal-3ds iframe {
    width: 100%;
    height: 100%; }
  .modal-3ds.show {
    display: block; }

.modal-3ds-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); }

.flex-button-share-container {
  display: flex;
  flex-wrap: wrap; }

.flex-button-share-container > div {
  width: 50px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px; }

.btn-blue-loading {
  padding: 15px 25px;
  font-family: "Gotham";
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #106397;
  border: 1px solid #106397;
  border-radius: 5px;
  pointer-events: none;
  -webkit-filter: opacity(0.7);
          filter: opacity(0.7); }
  .btn-blue-loading:disabled {
    pointer-events: none;
    -webkit-filter: opacity(0.7);
            filter: opacity(0.7); }
  .btn-blue-loading.btn-sm {
    padding: 5px 10px; }

.load {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 5px solid #fff;
  border-radius: 100%;
  margin-right: 0.5rem; }
  .load.open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
    animation: load-animate infinite linear 1s; }

@-webkit-keyframes load-animate {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg);
    opacity: 0.35; }
  100% {
    transform: rotate(360deg); } }

@keyframes load-animate {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg);
    opacity: 0.35; }
  100% {
    transform: rotate(360deg); } }

.card-total {
  display: flex;
  justify-content: flex-end; }
  .card-total * {
    width: auto;
    padding: 12px 25px;
    font-family: "Gotham-Bold";
    text-transform: uppercase;
    background-color: white;
    border-radius: 5px; }

.login {
  display: flex;
  justify-content: center;
  align-items: center; }
  .login .section-breadcrumbs {
    padding-bottom: 0; }
    .login .section-breadcrumbs .breadcrumbs-link {
      position: absolute;
      top: 58px;
      left: 40px; }
  .login.forgot-password h2 {
    margin-left: 35px; }
  .login.forgot-password .form-wrapper .form-field {
    margin-bottom: 0; }
  .login.success-payment .title {
    text-align: center; }
  .login .review-wrapper {
    margin-top: 25px;
    margin-bottom: 15px; }
    .login .review-wrapper .review-holder {
      display: flex;
      margin-bottom: 7px; }
      .login .review-wrapper .review-holder p {
        flex: 0 0 50%;
        justify-content: space-between; }
        .login .review-wrapper .review-holder p:first-child {
          font-family: "Gotham-Bold"; }
  .login .form-wrapper .react-tel-input .form-control {
    padding: 18.5px 14px 18.5px 58px; }
  .login .form-wrapper .react-tel-input .flag-dropdown {
    top: 0; }
  .login.verification .card-wrapper .btn-blue, .login.verification .card-wrapper .btn-blue-outline {
    margin: 0; }
  .login.verification .card-wrapper .btn-red {
    margin-left: 15px; }

@media screen and (max-width: 575px) {
  .login .review-wrapper .review-holder {
    display: block; } }

.swiper-slide {
  width: 100%;
  height: 100px;
  border: 1px red solid; }

.about .card-wrapper {
  max-width: 700px; }

.about .content {
  padding-bottom: 10px; }

.about .text-bold {
  font-family: "Gotham-Bold"; }

.about .category-wrapper {
  background-color: white;
  padding: 15px 0; }

.about a {
  font-family: "Gotham-Bold";
  color: #106397; }

.about ul li:before {
  content: '\2022';
  margin-right: 10px;
  font-size: 17px; }

.about li {
  padding-bottom: 7px; }

.deal-detail .title-centered {
  margin-bottom: 25px; }

.deal-detail .deal-detail-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 34px;
  background-color: white;
  border-radius: 5px; }

.deal-detail .form-detail-holder {
  padding-right: 40px;
  width: 60%;
  border-right: 1px solid lightgray; }

.deal-detail .tip-detail-holder {
  padding: 25px 40px;
  width: 40%; }

.deal-detail .form-textarea {
  padding-bottom: 0; }

.deal-detail .upload-wrapper {
  display: flex; }

.deal-detail .upload-holder {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #f4f4f4; }

.deal-detail .file-title {
  margin-bottom: 25px; }

.deal-detail .image-holder {
  display: flex;
  align-items: center;
  width: auto; }

.deal-detail .image-featured {
  display: flex;
  align-items: center;
  width: 300px; }

.deal-detail .file-upload-holder {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 50%; }

.deal-detail .upload-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.deal-detail .upload-file + label {
  display: inline-block;
  padding: 5px 8px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: white;
  background-color: gray;
  border-radius: 9px;
  cursor: pointer; }

.deal-detail .file-no-upload {
  margin-left: 4px; }
  .deal-detail .file-no-upload.isUploaded {
    display: none; }

.deal-detail .form-deal-submit {
  max-width: 300px; }

.deal-detail .btn-remove {
  padding: 6px 0;
  width: 100%;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  color: blue; }

.deal-detail .btn-wrapper {
  margin-top: 50px; }

.deal-detail .featured-holder {
  display: flex;
  align-items: center;
  margin: 15px 0; }
  .deal-detail .featured-holder .text-bold {
    padding: 0; }

.deal-detail .featured-button-holder {
  margin: 0 15px; }
  .deal-detail .featured-button-holder .switch {
    margin: 0 8px; }
  .deal-detail .featured-button-holder .btn-light {
    color: #212529;
    background-color: #e2e6ea; }
  .deal-detail .featured-button-holder .switch.off .switch-group {
    left: -50%; }
  .deal-detail .featured-button-holder .switch.off .switch-handle.btn-light {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .deal-detail .featured-button-holder .switch-on,
  .deal-detail .featured-button-holder .switch-off {
    display: none; }
  .deal-detail .featured-button-holder .switch-group {
    width: 100%; }
  .deal-detail .featured-button-holder .switch.btn {
    min-width: 40px;
    min-height: 20px;
    border-radius: 50px; }
  .deal-detail .featured-button-holder .switch-handle {
    top: -3.5px;
    left: 10px;
    padding: 7px 7px;
    width: 2px;
    height: 13px;
    border-radius: 100%;
    cursor: pointer; }

.deal-detail .tip-list {
  margin-bottom: 15px; }

.deal-detail .tip-title {
  display: flex;
  align-items: center;
  font-family: "Gotham";
  font-weight: 700; }

.deal-detail .tip-title-span {
  margin-right: 12px;
  width: 36px; }

.deal-detail .tip-list-title {
  margin-top: 20px; }

.deal-detail .tip-text {
  font-family: "Gotham";
  color: #7e7e7e; }

.business-profile .section-breadcrumbs.m-3 {
  margin: 1rem 0 !important; }

.business-profile .section-breadcrumbs .container {
  padding: 0; }

.business-profile .section-tab {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px; }
  .business-profile .section-tab .tab-nav {
    width: 20%; }
  .business-profile .section-tab .tab-infinity-transaction .heading,
  .business-profile .section-tab .tab-contact-details .heading {
    text-align: center;
    padding-bottom: 25px; }
  .business-profile .section-tab .nav-link {
    padding: 7px 0;
    font-family: "Gotham-Bold";
    color: #7e7e7e; }
    .business-profile .section-tab .nav-link.active {
      color: #106397; }
  .business-profile .section-tab .tab-content-holder {
    width: 80%; }
  .business-profile .section-tab .tab-content {
    width: 100%; }
  .business-profile .section-tab .form-wrapper .form-btn-dual-wrapper {
    justify-content: space-between; }
  .business-profile .section-tab .btn-blue, .business-profile .section-tab .btn-blue-outline {
    width: 48%; }
    .business-profile .section-tab .btn-blue.right, .business-profile .section-tab .right.btn-blue-outline {
      margin-left: auto;
      max-width: 215px; }
    .business-profile .section-tab .btn-blue.center, .business-profile .section-tab .center.btn-blue-outline {
      margin-left: auto;
      margin-right: auto; }
    .business-profile .section-tab .btn-blue.full-width, .business-profile .section-tab .full-width.btn-blue-outline {
      width: 100%; }
  .business-profile .section-tab .business-image-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .business-profile .section-tab .business-image-list {
    width: 48%;
    margin-bottom: 15px; }
  .business-profile .section-tab .business-image {
    width: 100%; }
  .business-profile .section-tab .deals-content-list {
    display: flex;
    flex-wrap: wrap; }
  .business-profile .section-tab .deals-image-holder {
    width: 55%; }
  .business-profile .section-tab .deals-info-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%; }
  .business-profile .section-tab .deals-content-list {
    margin-bottom: 20px; }
  .business-profile .section-tab .deals-title {
    font-weight: bold; }

@media (min-width: 992px) and (max-width: 1199px) {
  .business-profile .section-tab .business-image-list {
    width: 48.5%; }
  .business-profile .section-tab .business-image {
    width: 100%; }
  .business-profile .section-tab .deals-image-holder {
    width: 50%; }
  .business-profile .section-tab .deals-image {
    width: 100%; }
  .business-profile .section-tab .deals-info-holder {
    padding: 0 14px;
    width: 50%; }
  .business-profile .section-tab .deals-title {
    font-size: 14px; } }

@media screen and (max-width: 1199px) {
  .business-profile .section-tab .tab-content-holder {
    width: 77%;
    margin-left: auto; } }

.favorites {
  padding-top: 36px; }
  .favorites .section-favorites {
    padding-top: 26px; }
    .favorites .section-favorites .items-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
    .favorites .section-favorites .item-holder {
      margin-right: 5%;
      margin-bottom: 50px;
      width: 30%; }
      .favorites .section-favorites .item-holder:nth-child(3n+3) {
        margin-right: 0; }
    .favorites .section-favorites .image-holder .fav-img {
      width: 100%;
      max-width: 100%;
      height: auto; }
    .favorites .section-favorites .info-holder {
      padding-top: 20px; }
      .favorites .section-favorites .info-holder .title {
        margin-bottom: 0;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: .6px; }
      .favorites .section-favorites .info-holder .location-text {
        font-size: 19px;
        letter-spacing: .5px;
        color: #909090; }
      .favorites .section-favorites .info-holder .desc {
        font-size: 18px;
        color: #909090; }
    .favorites .section-favorites .price-holder {
      display: flex;
      align-items: center;
      padding-top: 7px; }
      .favorites .section-favorites .price-holder .old-price {
        position: relative;
        padding-right: 16px;
        font-size: 25px;
        color: #909090; }
        .favorites .section-favorites .price-holder .old-price .sign {
          padding-right: 7px; }
        .favorites .section-favorites .price-holder .old-price:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          left: 3px;
          height: 2px;
          width: 72%;
          background-color: #909090; }
      .favorites .section-favorites .price-holder .new-price {
        padding-right: 16px;
        font-size: 32px;
        font-weight: 600;
        color: #286df9; }
      .favorites .section-favorites .price-holder .discount {
        padding: 0px 7px;
        font-size: 15px;
        text-transform: uppercase;
        color: #286df9;
        background-color: #ccdcfd; }

@media screen and (max-width: 991px) {
  .favorites {
    padding-top: 16px; }
    .favorites .section-favorites {
      padding-top: 12px; }
      .favorites .section-favorites .item-holder {
        margin-right: 6%;
        width: 47%; }
        .favorites .section-favorites .item-holder:nth-child(2n+2) {
          margin-right: 0; }
        .favorites .section-favorites .item-holder:nth-child(3n+3) {
          margin-right: 6%; }
      .favorites .section-favorites .info-holder .title {
        font-size: 20px; }
      .favorites .section-favorites .info-holder .location-text {
        font-size: 16px; }
      .favorites .section-favorites .info-holder .desc {
        font-size: 14px; }
      .favorites .section-favorites .price-holder {
        padding-top: 8px; }
        .favorites .section-favorites .price-holder .old-price {
          font-size: 20px; }
        .favorites .section-favorites .price-holder .new-price {
          font-size: 22px; } }

@media screen and (max-width: 575px) {
  .favorites .section-favorites .item-holder {
    margin-right: 0%;
    margin-bottom: 30px;
    width: 100%; }
    .favorites .section-favorites .item-holder:nth-child(2n+2) {
      margin-right: 0; }
    .favorites .section-favorites .item-holder:nth-child(3n+3) {
      margin-right: 0; } }

.help {
  padding: 35px 0; }
  .help .faq-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 33px 130px; }
  .help .faq-holder {
    margin-right: 10%;
    margin-bottom: 40px;
    width: 45%; }
    .help .faq-holder:nth-child(2n+2) {
      margin-right: 0; }
  .help .faq-card {
    position: relative;
    padding-top: 13px;
    height: 246px; }
  .help .faq-caption {
    font-size: 23px; }
  .help .image-holder .faq-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px; }
  .help .title-holder {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px 12px 19px;
    background-color: #286df9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; }
    .help .title-holder .title {
      max-width: 200px;
      margin-bottom: 0;
      font-size: 20px;
      color: #ffffff; }
  .help .cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11px; }
    .help .cta-wrapper .cta-desc {
      font-size: 25px;
      text-align: center;
      word-spacing: 3px;
      color: #878787; }
    .help .cta-wrapper .btn-holder {
      padding-top: 55px; }
      .help .cta-wrapper .btn-holder .icon-holder {
        padding-right: 7px; }
        .help .cta-wrapper .btn-holder .icon-holder path {
          fill: #ffffff; }
      .help .cta-wrapper .btn-holder .btn-blue, .help .cta-wrapper .btn-holder .btn-blue-outline {
        padding: 20px 35px;
        width: auto; }
        .help .cta-wrapper .btn-holder .btn-blue:active .icon-holder path, .help .cta-wrapper .btn-holder .btn-blue-outline:active .icon-holder path, .help .cta-wrapper .btn-holder .btn-blue:focus .icon-holder path, .help .cta-wrapper .btn-holder .btn-blue-outline:focus .icon-holder path, .help .cta-wrapper .btn-holder .btn-blue:hover .icon-holder path, .help .cta-wrapper .btn-holder .btn-blue-outline:hover .icon-holder path {
          fill: #286df9; }

@media screen and (max-width: 1199px) {
  .help .faq-wrapper {
    padding: 13px 50px; } }

@media screen and (max-width: 991px) {
  .help .faq-wrapper {
    padding: 13px 0; }
  .help .faq-card {
    height: 227px; }
  .help .faq-caption {
    font-size: 19px; }
  .help .title-holder .title {
    max-width: 150px;
    font-size: 16px; }
  .help .cta-wrapper .cta-desc {
    font-size: 22px; } }

@media screen and (max-width: 767px) {
  .help {
    padding: 13px 0; }
    .help .faq-wrapper {
      padding: 0; }
    .help .faq-holder {
      width: 100%;
      margin-right: 0; }
    .help .faq-card {
      height: auto; }
    .help .title-holder {
      bottom: 0; }
    .help .cta-wrapper .cta-desc {
      font-size: 20px; }
    .help .cta-wrapper .btn-holder {
      padding-top: 35px; }
      .help .cta-wrapper .btn-holder .btn-blue, .help .cta-wrapper .btn-holder .btn-blue-outline {
        padding: 15px 18px;
        font-size: 14px; } }

.scanner .section-table .title-centered {
  padding: 25px 0;
  font-size: 20px; }

.infinity-points .content-wrapper {
  padding: 3px 66px; }

.infinity-points .points-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 48px; }

.infinity-points .points-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 294px;
  height: 244px;
  background-color: #e9f0ff;
  border-radius: 7px; }

.infinity-points .title-blue {
  font-weight: 700;
  font-size: 65px;
  color: #286df9; }

.infinity-points .text {
  font-size: 21px;
  font-weight: 500;
  color: #878787; }

.infinity-points .desc {
  font-size: 25px;
  text-align: center;
  color: #878787; }

.infinity-points .history-wrapper {
  padding-top: 86px; }
  .infinity-points .history-wrapper .title-black {
    padding-bottom: 33px; }
  .infinity-points .history-wrapper .link-holder {
    display: flex;
    justify-content: flex-end;
    padding-top: 11px; }

.infinity-points .info-wrapper {
  display: flex;
  padding-top: 123px; }

.infinity-points .info-holder {
  width: 50%; }

.infinity-points .list-info .item-info {
  position: relative;
  padding-left: 14px;
  margin-bottom: 6px;
  font-size: 20px;
  color: #878787; }
  .infinity-points .list-info .item-info:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    display: block;
    width: 5px;
    height: 2px;
    background: #878787; }

@media screen and (max-width: 1199px) {
  .infinity-points .content-wrapper {
    padding: 0 46px; }
  .infinity-points .points-info {
    width: 254px;
    height: 204px; } }

@media screen and (max-width: 991px) {
  .infinity-points .content-wrapper {
    padding: 0; }
  .infinity-points .title-blue {
    font-size: 50px; }
  .infinity-points .text {
    font-size: 19px; }
  .infinity-points .desc {
    font-size: 20px; }
  .infinity-points .points-info {
    width: 214px;
    height: 184px; }
  .infinity-points .history-wrapper {
    padding-top: 36px; }
  .infinity-points .info-wrapper {
    justify-content: space-between;
    padding-top: 83px; }
  .infinity-points .info-holder {
    width: 48%; }
  .infinity-points .list-info .item-info {
    font-size: 16px; } }

@media screen and (max-width: 767px) {
  .infinity-points {
    padding: 43px 0; }
    .infinity-points .points-wrapper {
      flex-direction: column; }
    .infinity-points .points-info {
      margin: 0 auto 25px;
      width: 290px;
      height: 184px; }
    .infinity-points .info-wrapper {
      flex-direction: column;
      padding-top: 53px; }
    .infinity-points .info-holder {
      width: 100%; } }

.infinity-card .swiper-content-wrapper {
  max-width: 600px;
  margin: auto; }

.infinity-card .card-swiper {
  position: relative;
  display: flex;
  align-items: center; }
  .infinity-card .card-swiper .heading {
    padding-bottom: 20px; }
  .infinity-card .card-swiper .swiper-container {
    max-height: 430px;
    height: 430px; }
  .infinity-card .card-swiper .swiper-slide {
    height: 400px;
    background-color: transparent; }
  .infinity-card .card-swiper .navigations-wrapper {
    position: static;
    position: initial; }
    .infinity-card .card-swiper .navigations-wrapper .back-btn {
      right: auto;
      left: -200px; }
    .infinity-card .card-swiper .navigations-wrapper .next-btn {
      right: -200px;
      left: auto;
      transform: rotate(180deg) translateY(60%); }

.infinity-card .infinity-card-img {
  max-width: 550px; }
  .infinity-card .infinity-card-img .img-card {
    max-width: 550px; }

.infinity-card .background {
  display: flex;
  justify-content: center;
  padding: 25px 50px;
  background-color: white;
  border-radius: 7px; }

.infinity-card .member-details-holder {
  position: relative;
  padding: 15px;
  padding-top: 30px;
  margin-bottom: 10px;
  height: 70px;
  background-color: white;
  border-radius: 7px; }
  .infinity-card .member-details-holder .member-details {
    font-size: 20px;
    font-family: "Gotham-Bold";
    text-transform: uppercase; }
  .infinity-card .member-details-holder .label {
    position: absolute;
    top: 10px;
    font-size: 13px;
    color: #909090; }

@media screen and (max-width: 1400px) {
  .infinity-card .card-swiper .navigations-wrapper .back-btn {
    right: auto;
    left: -50px; }
  .infinity-card .card-swiper .navigations-wrapper .next-btn {
    right: -50px;
    left: auto; } }

.member-account .heading {
  padding-bottom: 25px; }

.member-account .infinity-card-img {
  max-width: 550px;
  margin: auto; }
  .member-account .infinity-card-img .img-card {
    max-width: 550px; }

.member-account .link-blue {
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 100% 1; }

.member-account .account-holder {
  max-width: 550px;
  margin: auto; }

.member-account .account-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap; }
  .member-account .account-details-holder {
    height: 80px;
    background-color: #ffffff;
    flex: 0 0 48%;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 7px;
    box-shadow: 10px 10px 30px #e8e7eb; }
    .member-account .account-details-holder:nth-child(n+5) {
      flex: 100% 1; }
    .member-account .account-details-holder:nth-child(n+6) {
      display: flex;
      align-items: center; }
  .member-account .account-details .label {
    font-size: 13px;
    padding-bottom: 5px;
    color: #909090; }
  .member-account .account-details .value {
    font-size: 20px;
    font-family: "Gotham-Bold"; }
  .member-account .account-details .text {
    font-size: 15px; }

.qrbg {
  background-color: white;
  padding: 50px 125px; }

.brbg {
  background-color: white;
  padding: 50px 40px; }

.infinity-account .heading {
  padding-bottom: 40px; }

.infinity-account .items-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 55px 0;
  margin: auto;
  max-width: 560px;
  background-color: white;
  border-radius: 7px; }

.infinity-account .btn-blue, .infinity-account .btn-blue-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 25px;
  margin-bottom: 7px;
  font-family: "Gotham-Bold";
  font-size: 18px;
  color: #000000;
  text-align: center;
  text-transform: none;
  text-transform: initial;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 7px;
  transition: ease-in-out .2s all; }
  .infinity-account .btn-blue:active, .infinity-account .btn-blue-outline:active, .infinity-account .btn-blue:focus, .infinity-account .btn-blue-outline:focus, .infinity-account .btn-blue:hover, .infinity-account .btn-blue-outline:hover {
    color: #ffffff;
    background: #073e64;
    background: linear-gradient(90deg, #073e64 0%, #0b6095 50%); }

@media screen and (max-width: 991px) {
  .infinity-account .btn-blue, .infinity-account .btn-blue-outline {
    font-size: 14px; } }

@media screen and (max-width: 767px) {
  .infinity-account .btn-blue, .infinity-account .btn-blue-outline {
    padding: 15px 25px;
    width: 48%; } }

@media screen and (max-width: 575px) {
  .infinity-account .btn-blue, .infinity-account .btn-blue-outline {
    margin: 10px 0;
    width: 100%; } }

.request .content-wrapper {
  padding-top: 50px; }

.request .text-holder {
  padding: 20px 0;
  margin: 0 auto;
  max-width: 800px; }

.request .btn-holder {
  display: flex; }
  .request .btn-holder .btn-blue, .request .btn-holder .btn-blue-outline {
    margin: 0 auto;
    width: 200px; }

@media screen and (max-width: 767px) {
  .request .content-wrapper {
    padding-top: 0; }
  .request .text-holder {
    padding: 10px 0 20px; } }

@media screen and (max-width: 575px) {
  .request .btn-holder .btn-blue, .request .btn-holder .btn-blue-outline {
    width: 100%; } }

.qualification .heading {
  padding-bottom: 20px; }

.qualification .qualification-wrapper {
  max-width: 560px;
  margin: auto;
  background-color: white;
  border-radius: 7px;
  padding: 55px; }

.qualification .qualification-holder {
  margin-bottom: 17px; }

.qualification .text-blue {
  padding-bottom: 9px;
  font-size: 18px; }

.transfer-points-wrapper {
  background-color: white;
  max-width: 580px;
  margin: auto;
  border-radius: 7px;
  padding: 50px 35px; }

.transfer-points .form-wrapper .heading-wrapper {
  margin-bottom: 25px;
  text-align: center; }

.transfer-points .form-wrapper .text-blue {
  font-size: 55px;
  line-height: 1;
  margin-bottom: 0; }

.transfer-points .form-wrapper .subtitle {
  font-size: 17px;
  color: #000000; }

.transfer-points .form-wrapper .note {
  margin-top: -10px;
  color: #909090; }

.transfer-points .form-wrapper .btn-holder {
  margin-top: 35px; }

.partner-dashboard {
  display: flex;
  align-items: center; }
  .partner-dashboard .content-wrapper {
    margin: auto;
    max-width: 600px; }
  .partner-dashboard .section-breadcrumbs {
    padding-bottom: 0; }
  .partner-dashboard .dashboard-wrapper .dashboard-item:nth-child(3) .icon-holder .icon-img {
    height: 105px; }
  .partner-dashboard.manual-input .content-wrapper {
    max-width: 800px; }
  .partner-dashboard.manual-input .section-breadcrumbs {
    position: absolute;
    top: 87px; }
    .partner-dashboard.manual-input .section-breadcrumbs.m-3 {
      margin: 1rem 0 !important; }
  .partner-dashboard.manual-input .dashboard-wrapper {
    justify-content: center;
    padding: 35px 1.25rem; }
  .partner-dashboard.manual-input .dashboard-item {
    flex-basis: 46%; }
    .partner-dashboard.manual-input .dashboard-item:first-child {
      margin-right: 25px; }
  .partner-dashboard.manual-purchase .section-breadcrumbs {
    position: relative;
    top: 0; }
    .partner-dashboard.manual-purchase .section-breadcrumbs.m-3 {
      margin: 1rem 0 !important; }
  .partner-dashboard.manual-redeem .section-breadcrumbs {
    position: relative;
    top: 0; }
    .partner-dashboard.manual-redeem .section-breadcrumbs.m-3 {
      margin: 1rem 0 !important; }
  .partner-dashboard.manual-redeem .points-wrapper, .partner-dashboard.purchase-redeem-details .points-wrapper {
    margin-bottom: 31px;
    text-align: center;
    line-height: 1; }
  .partner-dashboard.manual-redeem .points-text, .partner-dashboard.purchase-redeem-details .points-text {
    font-family: "Gotham-Bold";
    font-size: 45px;
    color: #106397; }
  .partner-dashboard.manual-redeem .points-subtext, .partner-dashboard.purchase-redeem-details .points-subtext {
    font-size: 15px; }

.purchase-redeem-details .purchase-content {
  padding: 35px 1.25rem; }
  .purchase-redeem-details .purchase-content .infinity-card-img {
    margin: 0 auto; }
  .purchase-redeem-details .purchase-content .points-wrapper {
    margin-top: 25px; }
  .purchase-redeem-details .purchase-content .details-wrapper {
    margin-top: 17px; }
  .purchase-redeem-details .purchase-content .form-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px; }
    .purchase-redeem-details .purchase-content .form-wrapper .form-input {
      padding: 0 20px;
      padding-top: 20px;
      height: 80px;
      font-family: "Gotham-Bold";
      font-size: 23px;
      color: #000000;
      background-color: #ffffff;
      box-shadow: 10px 10px 30px #e8e7eb; }
    .purchase-redeem-details .purchase-content .form-wrapper .form-field {
      flex: 0 0 48%;
      margin-bottom: 25px; }
      .purchase-redeem-details .purchase-content .form-wrapper .form-field label {
        top: 10px;
        left: 20px;
        font-family: "Gotham";
        font-size: 15px;
        text-transform: capitalize; }
      .purchase-redeem-details .purchase-content .form-wrapper .form-field:last-child {
        flex: 100% 1;
        overflow: hidden; }

.member-dashboard .content-wrapper {
  margin-left: 23.15%;
  margin-right: 7.5%;
  padding: 50px 15px; }

.member-dashboard.member-referrals .section-filter .form-wrapper.transactions .form-group {
  margin-right: 0; }

.member-dashboard.member-referrals .section-filter .form-wrapper.transactions .single-input {
  margin-right: 15px; }

.member-dashboard.member-referrals .section-filter .form-wrapper.transactions .form-col {
  margin-right: 15px; }

@media screen and (max-width: 1199px) {
  .member-dashboard .content-wrapper {
    margin-left: 23.5%;
    margin-right: 3.5%; } }

.member-data .account-form .heading {
  padding-bottom: 25px;
  font-size: 25px; }

.member-data .account-form .form-wrapper .form-input {
  padding: 15px 10px;
  padding-top: 32px;
  font-family: "Gotham-Bold";
  font-size: 17px;
  color: #7e7e7e;
  background-color: #ffffff;
  border-color: #ffffff; }

.member-data .account-form .form-wrapper .form-field {
  margin-bottom: 20px;
  pointer-events: none; }
  .member-data .account-form .form-wrapper .form-field label {
    top: 0;
    left: 10px;
    font-size: 18px;
    color: #000000;
    text-transform: capitalize;
    letter-spacing: .15px; }

.member-data .radio-wrapper .form-field .radio-text {
  padding-left: 35px; }

.transaction-statement .section-breadcrumbs.m-3 {
  margin: 1rem 0 !important; }

.transaction-statement .section-breadcrumbs .container {
  padding: 0; }

.transaction-statement .statement-total {
  display: flex;
  justify-content: space-around;
  width: 37%;
  margin-left: auto;
  padding: 12px 25px;
  margin-top: 25px;
  background-color: white;
  border-radius: 7px; }
  .transaction-statement .statement-total p {
    font-family: "Gotham-Bold";
    text-transform: uppercase; }

.member-profile .section-breadcrumbs.m-3 {
  margin: 1rem 0 !important; }

.member-profile .section-breadcrumbs .container {
  padding: 0; }

.member-profile .section-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px; }
  .member-profile .section-tab .tab-nav {
    width: 20%; }
  .member-profile .section-tab .tab-content-holder {
    width: 80%; }
  .member-profile .section-tab .nav-link {
    font-family: "Gotham-Bold";
    color: #7e7e7e; }
    .member-profile .section-tab .nav-link.active {
      color: #106397; }

@media screen and (max-width: 991px) {
  .member-profile .container {
    max-width: 100%; } }

.notification-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: auto;
  max-width: 560px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 10px 10px 30px #e8e7eb; }
  .notification-wrapper .title {
    position: relative;
    margin-bottom: 25px;
    font-family: "Gotham-Bold"; }
  .notification-wrapper .notification-holder {
    position: relative;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c6cad3; }
    .notification-wrapper .notification-holder:last-child {
      padding-bottom: 0;
      border: 0; }
  .notification-wrapper .notif-clear-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    padding: 5px 10px;
    color: #106397;
    border-radius: 5px;
    transition: all 200ms ease-in-out; }
    .notification-wrapper .notif-clear-btn:active, .notification-wrapper .notif-clear-btn:focus, .notification-wrapper .notif-clear-btn:hover {
      color: #ffffff;
      background-color: #106397; }
  .notification-wrapper .notif-title {
    display: -webkit-box;
    padding-right: 30px;
    margin-bottom: .25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Gotham-Bold";
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
  .notification-wrapper .notif-remove {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 10px;
    color: #106397;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms ease-in-out; }
    .notification-wrapper .notif-remove:active, .notification-wrapper .notif-remove:focus, .notification-wrapper .notif-remove:hover {
      color: #ffffff;
      background-color: #106397; }
  .notification-wrapper .notif-body {
    font-size: 20px;
    font-weight: 300; }
  .notification-wrapper .notif-date {
    display: block;
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: 200; }
  .notification-wrapper .no-notif {
    color: #c6cad3;
    text-align: center; }

.admin-dashboard {
  display: flex;
  align-items: center; }
  .admin-dashboard .section-breadcrumbs {
    position: absolute;
    top: 87px; }
  .admin-dashboard .dashboard-wrapper {
    padding: 50px 0; }
    .admin-dashboard .dashboard-wrapper .dashboard-item:active .icon-holder .icon-img, .admin-dashboard .dashboard-wrapper .dashboard-item:focus .icon-holder .icon-img, .admin-dashboard .dashboard-wrapper .dashboard-item:hover .icon-holder .icon-img {
      -webkit-filter: brightness(10);
              filter: brightness(10); }

.manage-pages .section-breadcrumbs.m-3 {
  margin: 1rem 0 !important; }

.manage-pages .section-breadcrumbs .container {
  padding: 0; }

.manage-pages .search-holder {
  margin-bottom: 25px; }
  .manage-pages .search-holder .search-input {
    padding: 7px 5px;
    margin: 0 15px;
    width: 250px;
    background-color: #ffffff;
    border: 0.5px solid #106397;
    border-radius: 5px; }
  .manage-pages .search-holder .btn-blue, .manage-pages .search-holder .btn-blue-outline {
    padding: 7px;
    margin-right: 5px;
    width: auto; }

.member-account .link-blue.account-about {
  cursor: pointer; }

.encash-points-wrapper {
  background-color: white;
  max-width: 580px;
  margin: auto;
  border-radius: 7px;
  padding: 50px 35px; }

.encash-points .form-wrapper .heading-wrapper {
  margin-bottom: 25px;
  text-align: center; }

.encash-points .form-wrapper .text-blue {
  font-size: 55px;
  line-height: 1;
  margin-bottom: 0; }

.encash-points .form-wrapper .subtitle {
  font-size: 17px;
  color: #000000; }

.encash-points .form-wrapper .note {
  margin-top: -10px;
  margin-bottom: 20px !important;
  color: #909090; }

.encash-points .form-wrapper .link-blue {
  font-weight: 500; }

.encash-points .form-wrapper .btn-blue, .encash-points .form-wrapper .btn-blue-outline,
.encash-points .form-wrapper .btn-blue-outline {
  margin-top: 10px; }

.encash-points .form-wrapper .encash-method-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px; }

.encash-points .form-wrapper .encash-method-holder {
  width: 175px;
  padding: 5px;
  margin: 0 10px;
  text-align: center;
  border-radius: 5px;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 10px 0px #c6cad3;
  box-sizing: border-box;
  transition: all .25s ease-in-out; }
  .encash-points .form-wrapper .encash-method-holder.active {
    border-color: #106397;
    box-shadow: 0; }
  .encash-points .form-wrapper .encash-method-holder:active, .encash-points .form-wrapper .encash-method-holder:focus, .encash-points .form-wrapper .encash-method-holder:hover {
    box-shadow: 0px 0px 15px 5px #106397; }

.encash-points .form-wrapper .encash-method-title {
  margin-top: 10px;
  margin-bottom: 0;
  color: #106397; }

.encash-points .form-wrapper .encash-review-holder {
  margin-bottom: 20px; }

.encash-points .form-wrapper .encash-agreement-holder .radio-text {
  color: #000000;
  font-family: "Gotham";
  font-weight: 100; }

.ReactTable .rt-noData {
  position: unset;
  top: unset;
  left: unset;
  transform: unset;
  -webkit-transform: unset;
  text-align: center; }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #013d5b;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

