// my-infinity-account-account
.member-account {
  .heading {
    padding-bottom: 25px;
    // text-align: center;
  }

  .infinity-card-img {
    max-width: 550px;
    margin: auto;

    .img-card {
      max-width: 550px;
    }
  }

  .link-blue {
    text-align: center;
    justify-content: center;
    align-items: center;
    flex: 100%;
  }

  .account {
    &-holder {
      max-width: 550px;
      margin: auto;
    }

    &-details {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-wrap: wrap;
  
      &-holder {
        // flex-direction: column;
        height: 80px;
        background-color: $white;
        flex: 0 0 48%;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 7px;
        box-shadow: 10px 10px 30px $lavender;
  
        &:nth-child(n+5) {
          flex: 100%;
        }
  
        &:nth-child(n+6) {
          display: flex;
          align-items: center;
        }
      }
  
      .label {
        font-size: 13px;
        padding-bottom: 5px;
        color: $gray-144;
      }
  
      .value {
        font-size: 20px;
        font-family: $font-gotham-bold;
      }
  
      .text {
        font-size: 15px;
      }
    }
  }
}
.qrbg{
  background-color: white;
  padding: 50px 125px;
}
.brbg{
  background-color: white;
  padding: 50px 40px;
}