.login {
  display: flex;
  justify-content: center;
  align-items: center;

  .section-breadcrumbs {
    padding-bottom: 0;

    .breadcrumbs-link {
      position: absolute;
      top: 58px;
      left: 40px;
    }
  }

  &.forgot-password {
    h2 {
      margin-left: 35px;
    }

    .form-wrapper {
      .form-field {
        margin-bottom: 0;
      }
    }
  }

  &.success-payment {
    .title {
      text-align: center;
    }
  }

  .review-wrapper {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 15px;

    .review-holder {
      display: flex;
      margin-bottom: 7px;

      p {
        flex: 0 0 50%;
        justify-content: space-between;

        &:first-child {
          font-family: $font-gotham-bold;
        }
      }
    }
  }

  .form-wrapper {
    .react-tel-input {
      .form-control {
        padding: 18.5px 14px 18.5px 58px;
      }

      .flag-dropdown {
        top: 0;
      }
    }
  }

  &.verification {
    .card-wrapper {
      .btn-blue {
        margin: 0;
      }
  
      .btn-red {
        margin-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .login {
    .review-wrapper {
      .review-holder {
        display: block;
      }
    }
  }
}