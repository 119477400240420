.flex-button-share-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-button-share-container > div {
  width: 50px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
