.notification-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: auto;
  max-width: 560px;
  background-color: $white;
  border-radius: 7px;
  box-shadow: 10px 10px 30px $lavender;

  .title {
    position: relative;
    margin-bottom: 25px;
    font-family: $font-gotham-bold;
  }

  .notification-holder {
    position: relative;

    margin-top: 1rem;
    padding-bottom: 1rem;
    
    border-bottom: 1px solid $gray;

    &:last-child {
      padding-bottom: 0;

      border: 0;
    }
  }

  .notif-clear-btn {
    position: absolute;
    top: 40px;
    right: 40px;

    padding: 5px 10px;

    color: $teal;

    border-radius: 5px;

    transition: all 200ms ease-in-out;

    @include on-event() {
      color: $white;

      background-color: $teal;
    }
  }

  .notif-title {
    display: -webkit-box;
    padding-right: 30px;
    margin-bottom: .25rem;
    overflow: hidden;
    
    text-overflow: ellipsis;
    font-family: $font-gotham-bold;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .notif-remove {
    position: absolute;
    top: 0;
    right: 0;

    padding: 2px 10px;

    color: $teal;
    font-size: 20px;

    border-radius: 5px;

    cursor: pointer;
    transition: all 200ms ease-in-out;

    @include on-event() {
      color: $white;

      background-color: $teal;
    }
  }

  .notif-body {
    font-size: 20px;
    font-weight: 300;
  }

  .notif-date {
    display: block;
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: 200;
  }

  .no-notif {
    color: $gray;
    text-align: center;
  }
}