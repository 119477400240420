
.admin-dashboard {
  display: flex;
  align-items: center;

  .section-breadcrumbs {
    position: absolute;
    top: 87px;
  }

  .dashboard-wrapper {
    padding: 50px 0;

    .dashboard-item {
      @include on-event() {
        .icon-holder {
          .icon-img {
            filter: brightness(10);
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1299px) {
//   .admin-dashboard {
//     .dashboard-item {
//       // flex-basis: 25%;

//       &:nth-child(3n+3) {
//         margin-right: 0;
//       }
//     }
//   }
// }

// @media (min-width: 1200px) and (max-width: 1299px) {
//   .admin-dashboard {
//     .dashboard-item {      
//       &:nth-child(4n+4) {
//         margin-right: 25px;
//       }
//     }
//   }
// }