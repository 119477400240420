.member-dashboard {
  .content-wrapper {
    margin-left: 23.15%;
    margin-right: 7.5%;
    padding: 50px 15px;
    // min-height: 85vh;
  }

  &.member-referrals {
    .section-filter {
      .form-wrapper {
        &.transactions {
          .form-group {
            margin-right: 0;
          }
          
          .single-input {
            margin-right: 15px;
          }

          .form-col {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@include respond-to(lg-down) {
  .member-dashboard {
    .content-wrapper {
      margin-left: 23.5%;
      margin-right: 3.5%;
    }
  }
}