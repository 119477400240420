.encash-points {
  &-wrapper {
    background-color: white;
    max-width: 580px;
    margin: auto;
    border-radius: 7px;
    padding: 50px 35px;
  }

  .form-wrapper {
    .heading-wrapper {
      margin-bottom: 25px;

      text-align: center;
    }
  
    .text-blue {
      font-size: 55px;
      line-height: 1;
      margin-bottom: 0;
    }

    .subtitle {
      font-size: 17px;
      color: $black;
    }

    .note {
      margin-top: -10px;
      margin-bottom: 20px !important;
      color: $gray-144;
    }

    .link-blue {
      font-weight: 500;
    }

    .btn-blue,
    .btn-blue-outline {
      margin-top: 10px;
    }

    .encash-method {
      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 40px;
      }

      &-holder {
        width: 175px;
        padding: 5px;
        margin: 0 10px;

        text-align: center;

        border-radius: 5px;
        border: 3px solid $white;
        box-shadow: 0px 0px 10px 0px $gray;
        box-sizing: border-box;

        transition: all .25s ease-in-out;

        &.active {
          border-color: $teal;
          box-shadow: 0;
        }

        @include on-event() {
          box-shadow: 0px 0px 15px 5px $teal;
        }
      }

      &-title {
        margin-top: 10px;
        margin-bottom: 0;

        color: $teal;
      }
    }

    .encash-review-holder {
      margin-bottom: 20px;
    }

    .encash-agreement-holder {
      .radio-text {
        color: $black;
        font-family: $font-gotham;
        font-weight: 100;
      }
    }
  }
}

// .swal2-popup {
//   &.swal2-modal {
//     &.swal2-icon-error {
//       &.swal2-show {
        // margin-left: 23.15%!important;
        // margin-right: 7.5%!important;
//       }
//     }
//   }
// }