.transaction-statement {
  .content-wrapper {
    // padding: 50px 0;
  }

  .section-breadcrumbs {
    &.m-3 {
      margin: 1rem 0!important;
    }

    .container {
      padding: 0;
    }
  }

  .statement-total {
    display: flex;
    justify-content: space-around;
    width: 37%;
    margin-left: auto;
    padding: 12px 25px;
    margin-top: 25px;

    background-color: white;
    border-radius: 7px;

    p {
      font-family: $font-gotham-bold;
      text-transform: uppercase;

    }
  }
}