.signup-steps {

  .step-title {
    margin-top: 1rem;
  }

  .step-list {
    margin-top: 1rem;
    padding-left: 1rem;

    list-style-type: disc;
  }

  .email {
    color: $teal;
    font-weight: 500;

    @include on-event() {
      color: $black;
    }
  }
}
.card-design{
  max-width:220px;
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.card-container{
  background-color: whitesmoke;
}
.card-title{
  text-align: center;
  color: red;
}
.padded-body{
  padding:20px
}