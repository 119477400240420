.featured-button-holder {
  margin: 0 15px;

  .switch {
    margin: 0 8px;
  }

  .btn-light {
    color: #212529;
    background-color: #e2e6ea;
  }

  .switch.off {
    .switch-group {
      left: -50%;
    }

    .switch-handle.btn-light {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }
  }

  .switch-on,
  .switch-off {
    display: none;
  }

  .switch-group {
    width: 100%;
  }

  .switch.btn {
    min-width: 40px;
    min-height: 20px;
    border-radius: 50px;
  }

  .switch-handle {
    top: -4.5px;
    left: 10px;

    padding: 7px 7px;
    width: 2px;
    height: 13px;

    border-radius: 100%;

    cursor: pointer;
  }
}