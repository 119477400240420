.card-total {
  display: flex;
  justify-content: flex-end;

  * {
    width: auto;
    padding: 12px 25px;

    font-family: "Gotham-Bold";
    text-transform: uppercase;

    background-color: white;
    border-radius: 5px;
  }
}