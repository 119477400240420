.dashboard-swiper {
  margin-bottom: 30px;

  .swiper-slide {
    border: none;
    padding: 10px 25px;
    height: 420px;
    overflow: hidden;

    // background-color: rgba(70,72,82,.3);

    // a {
    //   @include on-event() {
    //     color: none;
    //   }
    // }
  }

  .swiper-container {
    max-height: 465px;
    height: 465px;
    
    > .swiper-pagination-bullets {
      bottom: 0;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        margin: 0 8px;
      }
    }
  }

  .top-wrapper {
    // position: relative;
    // top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .top-logo {
    width: 55px;
    background-color: $white;
    object-fit: contain;
    margin-top: -15px;
    padding: 10px 5px 5px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .top-buttons {
    display: flex;
    align-items: center;
    width: 80px;
    justify-content: space-between;
  }

  .top-icons {
    font-size: 35px;
    color: $white;
    
    cursor: pointer;
    transition: ease-in-out .2s all;

    @include on-event() {
      color: $teal
    }
  }

  .swiper-content {
    padding-top: 25px;
    max-width: 70%;

    line-height: 1;
    // text-shadow: 1px 1px 3px #7e7e7e
  }

  .deal-title {
    font-family: $font-gotham-bold;
    font-size: 65px;
    text-transform: uppercase;
    color: $white;
  }

  .deal-description {
    font-size: 25px;
    color: $white;
  }

  .deal-link {
    position: absolute;
    background-color: $teal;
    border: 1.5px solid $teal;
    color: $white;
    padding: 7px;
    margin-top: 15px;
    transition: ease-in-out .3s all;
    
    cursor: pointer;

    @include on-event() {
      background-color: transparent;
    }
  }

  .deal-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -5;
    width: 100%;
    max-height: 420px;
    object-fit: cover;
    // object-position: top;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   background-color: rgba(70,72,82,.3);
    // }
  }

  .navigations-wrapper {
    position: relative;
    top: -20px;
    z-index: 9999;

    .back-btn {
      right: auto;
      left: 0;
    }
    
    .next-btn {
      right: 0;
      left: auto;
      
      transform: rotate(180deg) translateY(60%);
    }
  }
}

@include respond-to(lg-down) {
  .dashboard-swiper {
    .swiper-container {
      height: 425px;
    }
  }
}