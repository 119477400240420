.member-data {
  .account-form {
    // margin: 0 auto;
    // max-width: 550px;
    // background-color: white;
    // padding: 35px;
    // border-radius: 7px;
    
    .heading {
      padding-bottom: 25px;

      font-size: 25px;
    }

    .form-wrapper {
      .form-input {
        padding: 15px 10px;
        padding-top: 32px;

        font-family: $font-gotham-bold;
        font-size: 17px;
        color: $gray-100;

        background-color: $white;
        border-color: $white;
      }

      .form-field {
        margin-bottom: 20px;

        pointer-events: none;

        label {
          top: 0;
          left: 10px;

          font-size: 18px;
          color: $black;
          text-transform: capitalize;
          letter-spacing: .15px;
        }
      }
    }
  }

  .radio-wrapper {
    .form-field {
      .radio-text {
        padding-left: 35px;
      }
    }
  }
}

