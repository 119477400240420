.help {
  padding: 35px 0;

  .faq-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 33px 130px;
  }

  .faq-holder {
    margin-right: 10%;
    margin-bottom: 40px;
    width: 45%;

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  .faq-card {
    position: relative;

    padding-top: 13px;
    height: 246px;
  }

  .faq-caption {
    font-size: 23px;
  }

  .image-holder {
    .faq-img {
      width: 100%;
      max-width: 100%;
      height: auto;

      border-radius: 8px;
    }
  }

  .title-holder {
    position: absolute;
    width: 100%;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px 12px 19px;

    background-color: $blue;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .title {
      max-width: 200px;
      margin-bottom: 0;

      font-size: 20px;
      color: $white;
    }
  }
  
  .cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11px;

    .cta-desc {
      font-size: 25px;
      text-align: center;
      word-spacing: 3px;
      color: $gray-135;
    }

    .btn-holder {
      padding-top: 55px;

      .icon-holder {
        padding-right: 7px;

        path {
          fill: $white ;
        }
      }

      .btn-blue {
        padding: 20px 35px;
        width: auto;

        @include on-event {
          .icon-holder {
            path {
              fill: $blue;
            }
          }
        }
      }
    }
  }
}

@include respond-to(lg-down) {
  .help {
    .faq-wrapper {
      padding: 13px 50px;
    }
  }
}

@include respond-to(md-down) {
  .help {
    .faq-wrapper {
      padding: 13px 0;
    }
    
    .faq-card {
      height: 227px;
    }

    .faq-caption {
      font-size: 19px;
    }

    .title-holder {
      .title {
        max-width: 150px;

        font-size: 16px;
      }
    }

    .cta-wrapper {
      .cta-desc {
        font-size: 22px;
      }
    }
  }
}

@include respond-to(sm-down) {
  .help {
    padding: 13px 0;

    .faq-wrapper {
      padding: 0;
    }

    .faq-holder {
      width: 100%;
      margin-right: 0;
    }

    .faq-card {
      height: auto;
    }

    .title-holder {
      bottom: 0;
    }
    
    .cta-wrapper {
      .cta-desc {
        font-size: 20px;
      }

      .btn-holder {
        padding-top: 35px;

        .btn-blue {
          padding: 15px 18px;

          font-size: 14px;
        }
      }
    }
  }
}