*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.btn,
a,
button {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  @include on-event {
    text-decoration: none!important;

    outline: 0;
    box-shadow: none;
  }
}

.secret-page {
  .back-btn {
    display: none;
  }
}

.hidden {
  opacity: 0;
  height: 1px; 
}

main {
  padding-top: 88px;
  overflow: hidden;

  // min-height: 85vh;
  min-height: 100vh;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  // background-image: url('../assets/images/ipc-bg.svg');
  // background-position: bottom;
  // background-repeat: no-repeat;
}

html {
  height: 100%;
}

html,
body {
  background-color: $white-smoke !important;
  // background-color: $snow!important;

}

/** Remove p margins **/
p {
  margin-bottom: 0 !important;
  padding: 0;
}

/**
 * Basic styles for input fields
 */
// input[type="email"],
// input[type="password"],
// input[type="text"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   -webkit-focus-ring-color: none;
//   outline: none;

//   @include on-event {
//     box-shadow: none;
//   }
// }

input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

input,
select,
button,
textarea {
  outline: none;
  @include appearance(none);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}

ul {
  padding: 0;

  list-style-type: none;
}

//temporary 

.section-notification {
  margin-bottom: 30px;

  .notification-text {
    margin: auto;
    padding: 15px;
    max-width: 500px;

    background-color: #fdf6f6;
    border-radius: 3px;
    border: 1px solid #f1c0bf;

    color: #dd6664;
    text-align: center;
  }
}

.custom-container {
  max-width: 1600px !important;
}

.payment-transactions-button {
  max-width: 200px !important;
  margin-bottom: 10px;
}

.custom-dashboard-container {
  // max-width: 1325px !important;
  margin-left: 440px !important;
}

// Animations

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 80px;
}

.lds-ellipsis .dots {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $teal;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis .dots:nth-child(1) {
  left: calc(45% + 8px);
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis .dots:nth-child(2) {
  left: calc(45% + 8px);
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis .dots:nth-child(3) {
  left: calc(45% + 32px);
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis .dots:nth-child(4) {
  left: calc(45% + 56px);
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}


@media screen and (max-width: 575px) {
  main {
    padding-top: 69px;
  }
}