.custom-pagination {
  display: flex;
  margin-top: 1rem;

  li {
    padding: 7px;
    margin: 0 1.5px;
    min-width: 35px;

    font-family: $font-gotham-bold;
    text-align: center;
    color: $gray-100;
    
    background-color: $white;
    border: 1px solid $gray;
    // border-right: 0;

    transition: ease-in-out .3s all;

    @include on-event() {
      color: $white;

      background-color: $teal;

      cursor: pointer;
    }

    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-right: 1px solid $gray;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.active {
      color: $white;

      background-color: $teal;

      cursor: default;
    }
  }

  .total-docs-label {
    padding: 7px;

    font-family: $font-gotham-bold;
    color: $gray-100;
  }
}