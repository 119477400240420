.modal-3ds {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;

  display: none;
  width: 600px;
  height: 70%;

  transform: translate(-50%, -50%);

  iframe {
    width: 100%;
    height: 100%;
  }

  &.show {
    display: block;
  }
}

.modal-3ds-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  width: 100%;
  height: 100%;

  background-color: rgba($color: #000000, $alpha: 0.25);
}
