.qualification {
  // display: flex;
  // justify-content: center;
  // // align-items: center;
  // flex-direction: column;
  // padding-top: 39px;
  // margin: 0 auto;

  .heading {
    padding-bottom: 20px;
  }

  .qualification-wrapper {
    // padding: 20px 0;
    // margin: 0 auto;
    // width: 100%;
    // max-width: 700px;
    max-width: 560px;
    margin: auto;
    background-color: white;
    border-radius: 7px;
    padding: 55px;
  }

  .qualification-holder {
    margin-bottom: 17px;
  }

  .text-blue {
    padding-bottom: 9px;
    font-size: 18px;
  }
}