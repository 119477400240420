.infinity-card {
  .swiper-content-wrapper {
    max-width: 600px;
    margin: auto;
  }

  .card-swiper {
    position: relative;

    display: flex;
    align-items: center;

    .heading {
      padding-bottom: 20px;
    }

    .swiper-container {
      max-height: 430px;
      height: 430px;
    }

    .swiper-slide {
      height: 400px;
      
      background-color: transparent;
    }

    .navigations-wrapper {
      position: initial;
      
      .back-btn {
        // top: 50%;
        right: auto;
        left: -200px;
      }
      
      .next-btn {
        // top: 47%;
        right: -200px;
        left: auto;
        
        transform: rotate(180deg) translateY(60%);
        // transform: rotate(-180deg);
      }
    }
  }

  .infinity-card-img {
    max-width: 550px;

    .img-card {
      max-width: 550px;
    }
  }

  .background {
    display: flex;
    justify-content: center;
    padding: 25px 50px;

    background-color: white;
    border-radius: 7px;
  }

  .member-details-holder {
    position: relative;

    padding: 15px;
    padding-top: 30px;
    margin-bottom: 10px;
    height: 70px;
    
    background-color: white;
    border-radius: 7px;

    .member-details {
      font-size: 20px;
      font-family: $font-gotham-bold;
      text-transform: uppercase;
    }

    .label {
      position: absolute;
      top: 10px;

      font-size: 13px;
      color: $gray-144;
    }
  }
}

@media screen and (max-width: 1400px) {
  .infinity-card {
    .card-swiper {
      .navigations-wrapper {
        .back-btn {
          right: auto;
          left: -50px;
        }

        .next-btn {
          // right: -50px;
          right: -50px;
          left: auto;
        }
      }
    }
  }
}