.header {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  // position: relative;
  z-index: 99999;
  max-height: 90px;
  // margin-bottom: 50px;
  // background-color: $white;
  background: rgb(7,62,100);
  background: linear-gradient(90deg, rgba(7,62,100,1) 0%, rgba(11,96,149,1) 15%, rgba(22,128,192,1) 30%, rgba(48,146,201,1) 50%, rgba(22,127,190,1) 70%, rgba(12,100,155,1) 85%, rgba(8,69,110,1) 100%);

  &.logged-in {
    .btn {
      font-weight: 100!important;
    }

    .navbar {
      justify-content: space-between!important;
      max-width: 100%;
      padding: .5rem 3rem;

      .btn-primary {
        color: white;
        background-color: transparent;
        padding: 0;
      }
    }

    .header-text-wrapper {
      display: flex;

      .header-text {
        padding-right: 15px;

        color: $white;
      }
    }
  }
}

.navbar {
  display: flex;
  // justify-content: space-between;
  justify-content: center!important;
  align-items: center;
  max-width: 1200px;
  // padding: 0 15px;
  margin: auto;

  .btn-blue {
    padding: 10px 25px;
    max-width: 150px;
  }

  .btn-primary {
    color: $black;

    background-color: $white;
    border: none;
  }

  .logo-header {
    max-width: 270px;
    width: 100%;
    height: auto;
    padding: 5px 0;
  }

  .btn-holder {
    width: 25%;
  }
}

.modal {
  // &-backdrop {
  //   background-color: $white;

  //   &.show {
  //     opacity: 0;
  //   }
  // }

  &-dialog {
    display: flex;
    align-items: center;
   
    min-height: 90%;
    height: auto;
  }

  &.logged-in {
    .btn-holder {
      display: none;
    }
  }
}

@media screen and (max-width: 575px) {
  .navbar {
    .logo-header {
      max-width: 190px;
    }
  }
}