.transfer-points {
  &-wrapper {
    background-color: white;
    max-width: 580px;
    margin: auto;
    border-radius: 7px;
    padding: 50px 35px;
  }

  .form-wrapper {
    .heading-wrapper {
      margin-bottom: 25px;

      text-align: center;
    }
  
    .text-blue {
      font-size: 55px;
      line-height: 1;
      margin-bottom: 0;
    }

    .subtitle {
      font-size: 17px;
      color: $black;
    }

    .note {
      margin-top: -10px;

      color: $gray-144;
    }

    .btn-holder {
      margin-top: 35px;
    }
  }
}

// .swal2-popup {
//   &.swal2-modal {
//     &.swal2-icon-error {
//       &.swal2-show {
        // margin-left: 23.15%!important;
        // margin-right: 7.5%!important;
//       }
//     }
//   }
// }