.sidebar-wrapper {
  // position: relative;
  // // flex: 0 0 20%;
  // float: left;
  // background-color: $white;
  // left: 0;
  // width: 20%;
  // height: 100%;
  box-shadow: 10px 10px 30px $lavender;
  height: 100%;
  width: 20.5%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $white;
  overflow-x: hidden;
  padding-top: 88px;

  &::-webkit-scrollbar {
    width: 7px;
  }
  
  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 1px grey; 
  //   border-radius: 10px;
  // }
  
  &::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: $gray-100; 
  }

  .sidebar-header {
    padding: 0 15px;
    margin-bottom: 23px;
    background-color: $white-smoke;
    height: 165px;
    align-items: center;
    // justify-content: center;
    display: flex;

    &.content {
      line-height: 1.25;
    }
  }

  .img-qr {
    // margin-right: 15px;
    // height: 125px;
    height: auto;
    max-width: 125px;
    width: 90px;
    // width: 125px;

    transition: ease-in-out .3s all;

    @include on-event() {
      filter: grayscale(1);
    }
  }

  .member-name {
    display: -webkit-box;
    font-family: $font-gotham-bold;
    font-size: 27px;
    color: $teal;
    line-height: 1;
    overflow: hidden;

    text-overflow: ellipsis;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .member-points {
    @extend .member-name;
    
    font-size: 16px;
  }
  
  .sidebar-link {
    font-family: $font-gotham-bold;
    color: $teal;

    // transition: all .3s ease-in-out;

    &-holder {
      position: relative;

      margin-bottom: 2.5px;
      border-radius: 3px;
      padding: 13px 15px;
      align-items: center;
      // background-color: black;
      cursor: pointer;
      transition: all .3s ease-in-out;

      @include on-event() {
        background: rgb(7,62,100);
        background: linear-gradient(90deg, rgba(7,62,100,1) 0%, rgba(11,96,149,1) 50%);

        .sidebar-link {
          color: $white;
        }
      }
    }
  }
}

@include respond-to(lg-down) {
  .sidebar-wrapper {
    .sidebar-header {
      display: block;
      height: 200px;

      text-align: center;

      .lds-ellipsis .dots:nth-child(1) {
        left: calc(25% + 8px);
        animation: lds-ellipsis1 0.6s infinite;
      }
      .lds-ellipsis .dots:nth-child(2) {
        left: calc(25% + 8px);
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis .dots:nth-child(3) {
        left: calc(25% + 32px);
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis .dots:nth-child(4) {
        left: calc(25% + 56px);
        animation: lds-ellipsis3 0.6s infinite;
      }
    }

    .img-qr {
      width: 105px;
    }
  }
}