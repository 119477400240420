.notif-badge {
  position: absolute;
  top: 50%;
  right: 20px;

  padding: 0 10px;

  color: $white;
  text-align: center;

  background-color: $red;
  border-radius: 10px;

  transform: translateY(-50%);
}