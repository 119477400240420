.btn-blue-loading {
  padding: 15px 25px;

  font-family: $font-gotham;
  color: $white;
  text-transform: uppercase;

  cursor: pointer;

  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  width: 100%;

  background-color: $teal;
  border: 1px solid $teal;
  border-radius: 5px;
  pointer-events: none;
  filter: opacity(0.7);

  &:disabled {
    pointer-events: none;
    filter: opacity(0.7);
  }

  &.btn-sm {
    padding: 5px 10px;
  }
}
.load {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 5px solid #fff;
  border-radius: 100%;
  margin-right: 0.5rem;
  &.open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
    animation: load-animate infinite linear 1s;
  }
}
@-webkit-keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
