.purchase-redeem-details {
  .purchase-content {
    // max-width: 520px;
    // margin: auto;
    // padding: 70px 15px;;
    padding: 35px 1.25rem;

    .infinity-card-img {
      margin: 0 auto;
    }

    .points-wrapper {
      margin-top: 25px;
    }

    .details-wrapper {
      margin-top: 17px;
    }
  
    .form-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;
  
      .form-input {
        padding: 0 20px;
        padding-top: 20px;
        height: 80px;
  
        font-family: $font-gotham-bold;
        font-size: 23px;
        color: $black;
  
        background-color: $white;
        box-shadow: 10px 10px 30px $lavender;
      }
  
      .form-field {
        flex: 0 0 48%;
        margin-bottom: 25px;
  
        label {
          top: 10px;
          left: 20px;
  
          font-family: $font-gotham;
          font-size: 15px;
          text-transform: capitalize;
        }
  
        &:last-child {
          flex: 100%;
          overflow: hidden;
        }
      }
    }
  }

}