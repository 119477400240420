.business-profile {
  // padding: 88px 0 0;

  // .breadcrumbs {
  //   .breadcrumbs-link {
  //     font-size: 22px;
  //   }
  // }

  .section-breadcrumbs {
    &.m-3 {
      margin: 1rem 0!important;
    }

    .container {
      padding: 0;
    }
  }

  .section-tab {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;

    .tab-nav {
      // padding-top: 25px;
      width: 20%;
    }

    .tab-infinity-transaction,
    .tab-contact-details {
      .heading {
        text-align: center;
        padding-bottom: 25px;
      }
    }
  
    .nav-link {
      padding: 7px 0;
      font-family: $font-gotham-bold;
      // font-weight: 500;
      color: $gray-100;
  
      &.active {
        color: $teal;
      }
    }
  
    .tab-content-holder {
      width: 80%;
    }

    .tab-content {
      width: 100%;
    }
    
    .form-wrapper {
      .form-btn-dual-wrapper {
        justify-content: space-between;
      }
    }

    .btn-blue {
      // max-width: 215px;
      width: 48%;

      &.right {
        margin-left: auto;
        max-width: 215px;
      }

      &.center {
        margin-left: auto;
        margin-right: auto;
      }

      &.full-width {
        width: 100%;
      }
    }
  
    .business-image-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .business-image-list {
      width: 48%;
      margin-bottom: 15px;
    }

    .business-image {
      width: 100%;
    }
  
    .deals-content-list {
      display: flex;
      flex-wrap: wrap;
    }
  
    .deals-image-holder {
      width: 55%;
    }
  
    .deals-info-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 45%;
    }
  
    .deals-content-list {
      margin-bottom: 20px;
    }
  
    .deals-title {
      font-weight: bold;
    }
  }
}

@include respond-to(lg) {
  .business-profile {
    .section-tab {

      .business-image-list {
        width: 48.5%;
      }

      .business-image {
        width: 100%;
      }

      .deals-image-holder {
        width: 50%;
      }

      .deals-image {
        width: 100%;
      }

      .deals-info-holder {
        padding: 0 14px;
        width: 50%;
      }

      .deals-title {
        font-size: 14px;
      }
    } 
  }
}

@include respond-to(lg-down) {
  .business-profile {
    .section-tab {
      .tab-content-holder {
        width: 77%;
        margin-left: auto;
      }
    }
  }
}