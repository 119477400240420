body {
  color: $text-color;
  font: 200 100% / 1.4 $font-gotham !important;
}

.title-centered {
  // font-size: 35px;
  // font-weight: 700;
  text-align: center;
}

.heading {
  font-size: 32px;
  font-family: $font-gotham-bold;

  // font-size: 31px;
  //   font-family: 'Gotham-Bold';
  //   letter-spacing: 1.25px;
  //   padding-bottom: 40px;
}

.text-blue {
  font-family: $font-gotham-bold;
  color: $teal;
}

.text-black {
  color: $black;
}

.hover-text-black {
  @include on-event {
    color: $black;
  }
}

@include respond-to(md-down) {
  .title-centered {
    font-size: 28px;
  }

  .title-black {
    font-size: 16px;
  }
}


@include respond-to(sm-down) {
  .title-centered {
    font-size: 22px;
  }
}