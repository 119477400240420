.tab-personal-data {

  .personal-data-title {
    text-align: center;
  }

  .personal-data-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;

    background-color: $white;
    border-radius: 8px;
  }

  .personal-data {
    margin-bottom: 1rem;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &.gender,
    &.title {
      width: 48%;
    }

    &.additional h4 {
      text-align: center;
    }

    p {
      font-weight: 500;
      color: $gray-100;
    }
  }
}