.infinity-account {
  .heading {
    padding-bottom: 40px;
  }

  .items-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 55px 0;
    margin: auto;
    max-width: 560px;

    background-color: white;
    border-radius: 7px;
  }

  .btn-blue {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 25px;
    margin-bottom: 7px;

    font-family: $font-gotham-bold;
    font-size: 18px;
    color: $black;
    text-align: center;
    text-transform: initial;
    
    background-color: $white;
    border-color: $white;
    border-radius: 7px;

    transition: ease-in-out .2s all;

    @include on-event() {
      color: $white;

      background: rgb(7,62,100);
      background: linear-gradient(90deg, rgba(7,62,100,1) 0%, rgba(11,96,149,1) 50%);
    }
  }
}


@include respond-to(md-down) {
  .infinity-account {
    .btn-blue {
      font-size: 14px;
    }
  }
}

@include respond-to(sm-down) {
  .infinity-account {
    .btn-blue {
      padding: 15px 25px;
      width: 48%;
    }
  }
}

@include respond-to(xs) {
  .infinity-account {
    .btn-blue {
      margin: 10px 0;

      width: 100%;
    }
  }
}