// infinity card img and details
.infinity-card-img {
  position: relative;

  max-width: 490px;

  .card-logo {
    position: absolute;
    top: 18px;
    left: 15px;
    width: 270px;
    height: auto;
    object-fit: contain;
  }
  
  .card-details {
    position: absolute;
    width: 100%;
    padding: 0 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    bottom: 0;

    color: $white;
  }

  .img-card {
    max-width: 490px;
    object-fit: contain;
    border-radius: 7px;
    box-shadow: 10px 10px 30px $lavender;
  }

  .tier-wrapper {
    margin-bottom: 6px;
    position: relative;
    right: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;

    .card-tier {
      position: absolute;
      right: 0;
      bottom: 0;
      object-fit: contain;
      width: 68px;
      height: 68px;
    }

    .tier-text {
      z-index: 999;
      font-family: $font-gotham-bold;
      font-size: 25px;
    }
  }

  .card-bottom-text {
    font-size: 11px;
  }

  .member {
    &-bottom-info {
      display: flex;
      justify-content: space-between;
    }

    &-name {
      font-family: $font-gotham-bold;
      font-size: 17px;
      text-transform: uppercase;
    }

    &-card-info {
      padding-bottom: 15px;

      line-height: 29px;
    }

    &-card-number {
      font-family: $font-gotham-bold;
      font-size: 31px;
      letter-spacing: 4px;
    }
  }
}