.infinity-points {
  .content-wrapper {
    padding: 3px 66px;
  }

  .points-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 48px;
  }

  .points-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 294px;
    height: 244px;

    background-color: $light-blue;
    border-radius: 7px;
  }

  .title-blue {
    font-weight: 700;
    font-size: 65px;
    color: $blue;
  }

  .text {
    font-size: 21px;
    font-weight: 500;
    color: $gray-135;
  }

  .desc {
    font-size: 25px;
    text-align: center;
    color: $gray-135;
  }

  //Transaction History
  .history-wrapper {
    padding-top: 86px;

    .title-black {
      padding-bottom: 33px;
    }

    .link-holder {
      display: flex;
      justify-content: flex-end;
      padding-top: 11px;
    }
  }


  //Information
  .info-wrapper {
    display: flex;
    padding-top: 123px;
  }

  .info-holder {
    width: 50%;
  }

  .list-info {
    .item-info {
      position: relative;

      padding-left: 14px;
      margin-bottom: 6px;

      font-size: 20px;
      color: $gray-135;

      &:before {
        content: '';

        position: absolute;
        top: 15px;
        left: 0;

        display: block;
        width: 5px;
        height: 2px;

        background: $gray-135;
      }
    }
  }
}

@include respond-to (lg-down) {
  .infinity-points {
    .content-wrapper {
      padding: 0 46px;
    }

    .points-info {
      width: 254px;
      height: 204px;
    }
  }
}

@include respond-to (md-down) {
  .infinity-points {
    .content-wrapper {
      padding: 0;
    }

    .title-blue {
      font-size: 50px;
    }

    .text {
      font-size: 19px;
    }

    .desc {
      font-size: 20px;
    }

    .points-info {
      width: 214px;
      height: 184px;
    }

    .history-wrapper {
      padding-top: 36px;
    }

    .info-wrapper {
      justify-content: space-between;
      padding-top: 83px;
    }

    .info-holder {
      width: 48%;
    }

    .list-info {
      .item-info {
        font-size: 16px;
      }
    }
  }
}

@include respond-to (sm-down) {
  .infinity-points {
    padding: 43px 0;

    .points-wrapper {
      flex-direction: column;
    }

    .points-info {
      margin: 0 auto 25px;
      width: 290px;
      height: 184px;
    } 

    .info-wrapper {
      flex-direction: column;
      padding-top: 53px;
    }

    .info-holder {
      width: 100%;
    }
  }
}