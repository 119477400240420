.section-filter {
  // padding-bottom: 20px;

  .accordion {
    border: none;

    &__panel {
      padding: 10px 0;
    }

    &__button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      padding: 0;

      font-family: $font-gotham-bold;
      text-align: left;
      color: $black;

      background-color: transparent;
      outline: 0;

      transition: ease-in-out 0.3s all;

      &:before {
        width: 8px;
        height: 8px;
        margin-left: 12px;
      }

      @include on-event() {
        color: $teal;

        background-color: transparent;
      }
    }
  }

  .filter-heading {
    padding-bottom: 30px;

    font-family: $font-gotham-bold;
    color: $black;
  }

  .filter-wrapper {
    display: flex;
    // justify-content: space-between;
    align-items: flex-end;
  }

  .form-wrapper {
    padding: 15px;
    align-items: center;
    justify-content: space-between;

    &.transactions {
      .filter-wrapper {
        max-width: 100%;
      }

      .group-wrapper {
        .form-group {
          margin-right: 70px;
        }

        &.date {
          width: 100%;

          .form-group {
            margin-right: 25px;
          }
        }

        &.name {
          .form-field {
            margin-right: 25px;
          }

          .single-input {
            width: 300px;
            margin-right: 25px;
          }
        }

        &.amount {
          width: 100%;

          .form-col {
            margin-right: 0;
          }
        }
      }

      .single-input {
        width: 370px;
        margin-right: 70px;
      }

      .form-col {
        margin-right: 70px;

        .form-field {
          &:first-child {
            margin-right: 15px;
          }
        }
      }
    }

    &.statement {
      .filter-wrapper {
        // max-width: 673px;
        max-width: 940px;
      }

      .group-wrapper {
        &.name {
          margin: 0 25px;
        }
      }
    }

    .form-group {
      justify-content: flex-start;
      margin-bottom: 0;
    }

    .form-field {
      margin-bottom: 0;
    }

    .calendar {
      width: 45%;

      &:first-child {
        margin-right: 15px;
      }

      .form-input {
        // padding-left: 40px;
      }

      .label-calendar {
        position: absolute;

        font-size: 19px;

        path {
          fill: $teal;
        }
      }

      .input-label {
        position: absolute;
        top: -25px;
      }
    }

    .width-full {
      width: 100%;
    }

    .name-field {
      width: 300px;
      max-width: 100%;
    }

    .form-input {
      height: 50px;
      padding: 15px;

      font-family: $font-gotham-bold;
      color: $teal;

      background-color: $white;
    }

    .btn-blue {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      margin-left: auto;
      padding: 10px 15px;
      height: 50px;
      width: 15%!important;
    }
  }
}

@include respond-to(lg-down) {
  .section-filter {
    .filter-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .group-wrapper {
      width: 50%;
      margin-bottom: 20px;

      &:not(:first-child) {
        .filter-heading {
          padding-bottom: 0;
        }
      }
    }

    .form-wrapper {
      &.transactions {
        .calendar {
          width: 48%;
        }

        .form-group {
          margin-right: 0;
          // justify-content: space-between;
        }

        .form-col {
          margin-right: 0;
        }

        .single-input {
          width: 100%;
          margin-right: 0;
        }
      }

      &.statement {
        .filter-wrapper {
          flex-direction: row;
          // align-items: center;
          max-width: 100%;
          flex-wrap: wrap;
        }

        .group-wrapper {
          padding-bottom: 20px;

          &.name {
            margin: 0;
          }

          &:not(:first-child) {
            .filter-heading {
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
