.edit-modal {
  .modal-dialog {
    // margin-left: 42.15%!important;
    // margin-left: 44.15% !important;
    // margin-left: 45.25% !important;
    // margin-right: 7.5%!important;
    // max-width: 575px;
  }

  .modal-content {
    // padding: 35px;
    // border-color: $white;
    // top: 60px;
  }

  h2 {
    display: none;
  }

  .heading {
    font-size: 20px;
    
    &-wrapper  {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
    }

    &-close {
      font-size: 25px;

      cursor: pointer;
      transition: ease-in-out .3s all;

      @include on-event() {
        color: $teal;
      }
    }
  }

  // .card-wrapper {
  //   &.no-margin {
  //     margin: auto;
  //   }
  // }
}