%btn {
  padding: 15px 25px;

  font-family: $font-gotham;
  color: $white;
  text-transform: uppercase;

  cursor: pointer;

  @include on-event() {
    color: $teal;

    background-color: transparent;

    transition: all 0.2s ease-in-out;
  }
}

.addSpace {
  margin-bottom: 10px;
}

.rightSpace {
  margin-right: 3px;
}

.btn-blue-no-width {
  @extend %btn;

  background-color: $teal;
  border: 1px solid $teal;
  border-radius: 5px;

  &:disabled {
    pointer-events: none;
    filter: opacity(0.7);
  }

  &.btn-sm {
    padding: 5px 10px;
  }
}

.btn-blue {
  @extend %btn;

  width: 100%;

  background-color: $teal;
  border: 1px solid $teal;
  border-radius: 5px;

  &:disabled {
    pointer-events: none;
    filter: opacity(0.7);
  }

  &.btn-sm {
    padding: 5px 10px;
  }
}

.btn-blue-outline {
  @extend %btn;
  @extend .btn-blue;

  background-color: transparent;
  color: $teal;

  @include on-event() {
    color: $white;

    background-color: $teal;

    transition: all 0.2s ease-in-out;
  }
}

.btn-red {
  @extend %btn;

  width: 100%;

  background-color: $red;
  border: 1px solid $red;
  border-radius: 5px;

  &:disabled {
    pointer-events: none;
    filter: opacity(0.7);
  }

  @include on-event() {
    color: $red;
  }
  &.btn-sm {
    padding: 5px 10px;
  }
}

.btn-gray {
  @extend %btn;

  width: 100%;

  background-color: $gray;
  border: 1px solid $gray;
  border-radius: 5px;

  @include on-event() {
    color: $white;

    background-color: $teal;
  }

  &.btn-sm {
    padding: 5px 10px;

    font-size: 12px;
  }

}

.disabled {
  pointer-events: none;
}

.btn-gray-outline-gray {
  padding: 15px 25px;

  font-family: $font-gotham;
  color: $white;
  text-transform: uppercase;

  cursor: pointer;

  background-color: transparent;
  border: 1px solid $teal;
  color: $teal;
  width: 100%;

  border-radius: 5px;

  &:disabled {
    pointer-events: none;
    filter: opacity(0.7);
  }

  &.btn-sm {
    padding: 5px 10px;
  }

  &.margin-top {
    margin-top: 0.5rem;
  }

  @include on-event() {
    color: $white;

    background-color: $teal;

    transition: all 0.2s ease-in-out;
  }
}

.btn-white {
  @extend %btn;

  color: $black;

  border: none;
}

.btn-yellow {
  @extend %btn;

  padding: 5px 20px;

  font-size: 25px;
  color: $black;

  background-color: $yellow;
  border-radius: 5px;

  @include on-event() {
    background-color: $white;
  }
}

.link-blue {
  font-size: 17px;
  text-decoration: none;
  color: $teal;

  // border-bottom: 1px solid $pale-blue;

  @include on-event {
    text-decoration: none;
    color: $dodger-blue;

    // border-bottom: 1px solid $teal;

    transition: all 0.2s ease-in-out;
  }
}

.icon-actions {
  width: 20px;
  height: 20px;

  path {
    transition: all 0.3s ease;
  }

  @include on-event {
    path {
      fill: $teal;
      stroke: $teal;
    }
  }
}

.back-btn {
  position: absolute;
  top: calc(50% + 2px);
  left: 0;

  transform: translateY(-50%);

  width: 25px;
  height: 25px;

  cursor: pointer;

  &:before {
    content: "";

    position: absolute;
    top: 7px;
    right: initial;
    bottom: initial;
    left: 5px;

    width: 13px;
    height: 2px;

    background-color: $teal;

    transform: rotate(135deg);
  }

  &:after {
    @extend .back-btn:before;

    top: 15px;

    transform: rotate(45deg);
  }
}

.btn-transparent {
  padding: 0;
  border-radius: 0;
  text-decoration: underline;

  border: 0;
  outline: none;

  &.red {
    color: red;
  }

  &.blue {
    color: blue;
  }
}

.export-dropdown {
  .dropdown-toggle {
    padding: 15px 25px;

    font-family: $font-gotham;
    text-transform: uppercase;
    color: $teal;

    background-color: transparent;
    border-color: $teal;

    @include on-event {
      color: $teal;
      
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }

    &:hover {
      color: $white; 
      background-color: $teal;
    }
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    color: $white;

    background-color: $teal;
    border-color: $teal;
    outline: none;
    box-shadow: none;

    @include on-event {
      outline: none;
      box-shadow: none;
    }
  }
}

.export-option {
  padding: 5px 15px;
  width: 100%;

  &:hover {
    background-color: $teal;

    & * {
      color: $white;
    }
  }

  & > * {
    display: block;
    width: 100%;
    cursor: pointer;
  }
}


@media screen and (max-width: 575px) {
  %btn {
    padding: 13px 25px;

    font-size: 14px;
  }
}

@include respond-to(md-down) {
  .btn-yellow {
    padding: 3px 14px;

    font-size: 20px;
  }

  .link-blue {
    font-size: 16px;
  }
}
