//business partner

.partner-dashboard {
  display: flex;
  align-items: center;

  .content-wrapper {
    // padding: 25px 0;
    margin: auto;
    max-width: 600px;
  }

  .section-breadcrumbs {
    padding-bottom: 0;
  }

  .dashboard-wrapper {
    .dashboard-item {
      &:nth-child(3) {
        .icon-holder {
          .icon-img {
            height: 105px;
          }
        }
      }
    }
  }

  &.manual-input {
    .content-wrapper {
      max-width: 800px;
    }

    .section-breadcrumbs {
      position: absolute;
      top: 87px;

      &.m-3 {
        margin: 1rem 0!important;
      }
    }

    .dashboard-wrapper {
      justify-content: center;
      padding: 35px 1.25rem;

      // .icon-holder {
      //   &:nth-of-type(3) {
      //     .icon-img {
      //       height: 100px;
      //     }

      //   }
      // }
    }

    .dashboard-item {
      flex-basis: 46%;

      &:first-child {
        margin-right: 25px;
      }
    }
  }

  &.manual-purchase {
    .section-breadcrumbs {
      position: relative;
      top: 0;

      &.m-3 {
        margin: 1rem 0!important;
      }
    }
  }

  &.manual-redeem {
    .section-breadcrumbs {
      position: relative;
      top: 0;

      &.m-3 {
        margin: 1rem 0!important;
      }
    }
  }

  &.manual-redeem,
  &.purchase-redeem-details {
    .points-wrapper {
      margin-bottom: 31px;
  
      text-align: center;
      line-height: 1;
    }
  
    .points-text {
      font-family: $font-gotham-bold;
      font-size: 45px;
      color: $teal;
    }
  
    .points-subtext {
      font-size: 15px;
    }
  }
}