.manage-pages {
  .section-breadcrumbs {
    &.m-3 {
      margin: 1rem 0!important;
    }

    .container {
      padding: 0;
    }
  }

  .search-holder {
    margin-bottom: 25px;

    .search-input {
      padding: 7px 5px;
      margin: 0 15px;
      width: 250px;

      background-color: $white;
      border: .5px solid $teal;
      border-radius: 5px;
    }

    .btn-blue {
      padding: 7px;
      margin-right: 5px;
      width: auto;
    }
  }

  // .form-wrapper {
  //   .form-field.form-textarea {
  //     padding: 19px 0 0;
  //   }
  // }
}