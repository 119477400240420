.dashboard-wrapper {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
  // padding: 50px 30px;

  .dashboard-item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 23.5%;
    // padding: 50px;
    margin-bottom: 40px;
    margin-right: 25px;
    // height: 282px;

    text-decoration: none;

    background-color: $white;
    // border: 4px solid $white;
    // border-bottom: 0;
    border-radius: 10px;
    box-shadow: 10px 10px 30px $lavender;

    // transition: all .1s ease-in-out;

    cursor: pointer;
    
    &:nth-child(4n+4) {
      margin-right: 0;
    }

    @include on-event {
      // border: 4px solid $dodger-blue;
      background-color: #d2d2d2;
      // border-bottom: 0;

      // transition: all .3s ease-in-out;

      // .title-item {
      //   color: $white;

      //   // transition: all .3s ease-in-out;
      // }
    }
  }

  .icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 50px;
    padding: 30px 30px 0;
    width: 100%;
    height: 100%;
    margin-bottom: 5px;

    .icon-img {
      // width: 125px;
      // height: 125px;
      width: 100px;
      height: 130px;
      object-fit: contain;
    }
  }
  
  .title-holder {
    padding: 0 15px 30px;
    width: calc(100% + 8px);

    text-align: center;

    // background-color: $dodger-blue;
    // border-bottom-left-radius: 7px;
    // border-bottom-right-radius: 7px;

    .title-item {
      font-family: $font-gotham-bold;
      // font-weight: 500;
      font-size: 17px;
      color: $text-color;
      line-height: 1;
    }
  }

  &.featured-deals {
    .icon-holder {
      padding: 0;
      margin-bottom: 0;

      .icon-img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }


    }

    .content-holder {
      position: absolute;
      z-index: 999;
    }

    .dashboard-item {
      position: relative;

      justify-content: flex-start;
      align-items: flex-start;
      height: 220px;

      cursor: initial;

      &:after {
        content: '';
        position: absolute;
        
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba(70,72,82,.3);
      }
    }

    .dashboard-swiper {
      .deal-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        margin-bottom: 7px!important;

        font-size: 25px;
      }

      .deal-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        
        font-size: 18px;
        line-height: 22px;
      }

      .swiper-content {
        max-width: 100%;
        padding: 35px 15px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1299px) {
  .dashboard-wrapper {
    .dashboard-item {
      flex-basis: 25%;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  // .admin-dashboard {
    .dashboard-wrapper {
      .dashboard-item {      
        &:nth-child(4n+4) {
          margin-right: 25px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  // }
}

@include respond-to (lg-down) {
  .dashboard-wrapper {
    padding: 30px 0;

    .dashboard-item {
      margin-right: 3.5%;

      &:nth-child(4n+4) {
        margin-right: 3.5%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

// @include respond-to(md-up) {
//   .dashboard-wrapper {
//     .dashboard-item {
//       flex-basis: 25%;
  
//       // &:nth-child(3n+3) {
//       //   margin-right: 0;
//       // }
//     }
//   }
// }

@include respond-to (md-down) {
  .dashboard-wrapper {
    .dashboard-item {
      flex-basis: 35%;
      margin-right: 8%;

      // &:nth-child(2n+2) {
      //   margin-right: 0;
      // }

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }
}

@include respond-to (sm-down) {
  .dashboard-wrapper {
    .dashboard-item {
      flex-basis: 47%;
      margin-right: 6%;
      margin-bottom: 20px;

      &:nth-child(3n+3) {
        margin-right: 6%;
      }
    }

    .icon-holder {
      .icon-img {
        width: 70px;
      }
    }

    .title-holder {
      padding: 15px 3px;

      .title-item {
        font-size: 13px;
      }
    }
  }
}

@include respond-to (xs) {
  .dashboard-wrapper {
    .dashboard-item {
      flex-basis: 48%;
      margin-right: 4%;

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      &:nth-child(3n+3) {
        margin-right: 4%;
      }
    }

    .icon-holder {
      padding: 40px 30px;

      .icon-img {
        width: 55px;
      }
    }

    .title-holder {
      padding: 10px 3px;

      .title-item {
        font-size: 10px;
      }
    }
  }
}