.card-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: auto;
  max-width: 560px;
  
  background-color: $white;
  border-radius: 7px;
  box-shadow: 10px 10px 30px $lavender;

  &.no-margin {
    margin: 0;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.has-back-button {
    .title {
      margin-left: 0;
      padding-left: 30px;
    }
  }

  a {
    color: $black;

    @include on-event() {
      color: $teal;
    }
  }

  h2 {
    position: relative;

    margin-bottom: 25px;

    font-family: $font-gotham-bold;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }

  .signup-option {
    padding-bottom: 20px;

    font-size: 17px;
    text-align: center;

    span {
      border-bottom: 1px solid $black;

      cursor: pointer;

      @include on-event() {
        color: $blue;

        border-color: $blue;
      }
    }
  }

  .divider {
    &:after {
      content: '';

      position: absolute;
      top: 152px;
      right: 0;
      left: 0;
      z-index: 0;
      
      margin: auto;
      width: 84%;
      height: 1px;
      
      background-color: $white-smoke;
    }
  }

  .divider {
    + p {
      position: relative;
      z-index: 0;

      padding-bottom: 25px;
      margin: auto;
      width: 35px;
      
      color: $gray-100;
      font-size: 16px;
      text-align: center;
      
      background: $white;
    }
  }

  .cta {
    margin-top: 25px;
    
    font-size: 15px;
    text-align: center;

    span {
      // a {
        color: $black;
        
        border-bottom: .5px solid $gray-144;

        cursor: pointer;

        transition: ease-in-out .3s all;

        @include on-event() {
          color: $teal;

          border-color: $teal;
        }
      // }
    }
  }

  .message {
    padding-bottom: 25px;
    margin: auto;
    max-width: 360px;

    font-size: 15px;
    color: $gray-100;
    text-align: center;
  }

  .success-icon {
    padding-bottom: 30px;
    margin: auto;
    width: 100px;
    height: 125px;
  }

  form {  
    .remember-password {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 30px;

      p {
        font-size: 15px;
      }
    }

    .checkbox-wrapper {
      position: relative;
  
      display: flex;
      align-items: center;
      
      input[type="checkbox"] {
        display: none;
    
        &:checked + label:after {
          opacity: 1;
          // background-color: $teal;
        }

        // &:checked + label {
        //   background-color: $teal;
        // }
      }
  
      label {
        position: relative;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
   
        cursor: pointer;

        &:before {
          content: '';
          
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 0;
          padding-left: 18px;
          width: 1px;
          min-height: 18px;
          border: 1px solid $gray;
          border-radius: 5px;        
        }
  
        &:after {
          content: '';
            
          position: absolute;
          top: 5px;
          left: 4.5px;
          
          width: 11px;
          height: 7px;
          
          background: transparent;
          border: 3px solid $teal;
          border-top: none;
          border-right: none;
          
          opacity: 0;
  
          transform: rotate(-45deg);
        }
  
        &:hover {
          &:after {
            opacity: 0;
          }
        }
      }
    }

    .form-field {
      &.phone {
        label {
          top: 10px;
          left: 18px;

          font-size: 13px;
          color: $gray-100;

          pointer-events: none;
  
          // &:after {
          //   content: '';
            
          //   position: absolute;
          //   top: -3px;
          //   left: 35px;
            
          //   width: 1px;
          //   height: 30px;
            
          //   background-color: $gray-100;
          // }
        }
      }
    }
  
  }

  .btn-blue,
  .btn-gray {
    margin-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .card-wrapper {
    padding: 25px 15px;

    h2 {
      margin-bottom: 0;

      font-size: 27px;
    }

    .cta {
      font-size: 13px;
    }
  }
}