.confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  
  display: none;
  width: 100%;
  height: 100%;

  background-color: rgba($color: $black, $alpha: .25);

  &.show-modal {
    display: block;
  }

  .confirm-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    
    width: 500px;
    padding: 25px;

    background-color: $white;
    border-radius: 8px;

    transform: translate(-50%, -50%);
  }

  .confirm-title {
    padding-bottom: 15px;

    border-bottom: 1px solid $gray;
  }

  .confirm-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    .btn-cancel {
      color: $blue;
      
      background-color: $white;

      &:hover {
        color: $white;

        background-color: $blue;
      }
    }

    .btn-confirm {
      margin-left: 25px;
    }
  }
}