.member-profile {
  // padding: 88px 0 0;
  .section-breadcrumbs {
    &.m-3 {
      margin: 1rem 0!important;
    }

    .container {
      padding: 0;
    }
  }

  .section-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 15px;

    .tab-nav {
      // padding-top: 25px;
      width: 20%;
    }

    .tab-content-holder {
      width: 80%;
    }

    .nav-link {
      // padding: 7px 0;

      font-family: $font-gotham-bold;
      // font-weight: 500;
      color: $gray-100;

      &.active {
        color: $teal;
      }
    }
  }
}

@include respond-to(md-down) {
  .member-profile {
    .container {
      max-width: 100%;
    }
  }
}