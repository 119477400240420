.deal-detail {
  // padding-top: 25px;

  .title-centered {
    margin-bottom: 25px;
  }

  .deal-detail-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding: 34px;

    background-color: white;
    border-radius: 5px;
  }

  .form-detail-holder {
    padding-right: 40px;
    width: 60%;

    border-right: 1px solid lightgray;
  }

  .tip-detail-holder {
    padding: 25px 40px;
    width: 40%;
  }

  .form-textarea {
    padding-bottom: 0;
  }

  // Upload

  .upload-wrapper {
    display: flex;
  }

  .upload-holder {
    padding: 15px;

    border-radius: 5px;
    border: 1px solid #f4f4f4;
  }

  .file-title {
    margin-bottom: 25px;
  }

  .image-holder {
    display: flex;
    align-items: center;
    width: auto;
  }
  .image-featured {
    display: flex;
    align-items: center;
    width: 300px;
  }

  .file-upload-holder {
    display: flex;
    flex-direction: column;
    
    padding: 15px;
    width: 50%;
  }

  .upload-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .upload-file + label {
    display: inline-block;
    padding: 5px 8px;
    margin: 0;

    font-size: 14px;
    font-weight: 700;
    color: white;
    
    background-color: gray;
    border-radius: 9px;

    cursor: pointer;
  }

  .file-no-upload {
    margin-left: 4px;

    &.isUploaded {
      display: none;
    }
  }

  .form-deal-submit {
    max-width: 300px;
  }

  // .upload-file:focus + label,
  // .upload-file + label:hover {
  //   background-color: red;
  // }

  .btn-remove {
    padding: 6px 0;
    width: 100%;
    max-width: max-content;

    color: blue;
  }

  // End upload

  // .form-input {
  //   cursor: pointer;
  // }

  .btn-wrapper {
    margin-top: 50px;
  }

  // Featured Button

  .featured-holder {
    display: flex;
    align-items: center;
    margin: 15px 0;

    .text-bold {
      padding: 0;
    }
  }

  .featured-button-holder {
    margin: 0 15px;

    .switch {
      margin: 0 8px;
    }

    .btn-light {
      color: #212529;
      background-color: #e2e6ea;
    }

    .switch.off {
      .switch-group {
        left: -50%;
      }

      .switch-handle.btn-light {
        background-color: #f8f9fa;
        border-color: #f8f9fa;
      }
    }

    .switch-on,
    .switch-off {
      display: none;
    }

    .switch-group {
      width: 100%;
    }

    .switch.btn {
      min-width: 40px;
      min-height: 20px;
      border-radius: 50px;
    }

    .switch-handle {
      top: -3.5px;
      left: 10px;

      padding: 7px 7px;
      width: 2px;
      height: 13px;

      border-radius: 100%;

      cursor: pointer;
    }
  }

  // Tip

  .tip-list {
    margin-bottom: 15px;
  }

  .tip-title {
    display: flex;
    align-items: center;

    font-family: $font-gotham;
    font-weight: 700;
  }

  .tip-title-span {
    margin-right: 12px;
    width: 36px;
  }

  .tip-list-title {
    margin-top: 20px;
  }

  .tip-text {
    font-family: $font-gotham;
    color: $gray-100;
  }
}