.carousel {
  &-item {
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out!important;
  }

  &-indicators {
    bottom: -50px!important;
    margin-right: 0!important;
    margin-left: 0!important;
    li {
      background-color: $dodger-blue!important;
    }
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23286df9' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")!important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23286df9' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")!important;
}

.carousel-control-prev,
.carousel-control-next  {
  width: 7%;
}