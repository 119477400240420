.section-table {
  padding-bottom: 25px;

  &.table-solo {
    margin-top: 50px;
  }

  .table-button-holder {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .btn-csv-export,
    .btn-pdf-export {
      width: 10% !important;
      min-width: 200px;

      text-align: center;
    }
  }

  .ReactTable {
    border: none !important;

    .rt-table {
      border-radius: 6px;

      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
    }

    .rt-tr {
      .rt-th div {
        font-family: $font-gotham;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .rt-thead {
      color: $white;

      background-color: $teal;
    }

    .rt-td {
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: $font-gotham;
      font-size: 14px;
      text-align: center;
    }

    .rt-th,
    .rt-td {
      padding: 15px !important;

      white-space: normal !important
    }

    .rt-td {

      &.table-point-plus:before {
        content: '+';
        position: relative;
        margin-right: 3px
      }

      &.table-amount-peso:before {
        content: 'P';
        position: relative;
        margin-right: 3px
      }

      .table-status-active,
      .table-status-inactive {
        position: relative;

        &:after {
          content: '';

          position: absolute;
          top: calc(50% + 2px);
          right: -18px;

          width: 9px;
          height: 9px;

          border-radius: 50%;

          transform: translateY(-50%);
        }
      }

      .table-status-active:after {
        background-color: green;
      }

      .table-status-inactive:after {
        background-color: red;
      }
    }

    .rt-th {
      display: flex;
      align-items: center;

      justify-content: center;
    }

    .rt-tr-group {
      background-color: $white;
    }

    .rt-resizable-header-content {
      text-align: left;
    }

    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .button-holder {
      width: auto;
    }

    .icon-link {
      display: block;
      padding: 0 6px;
      height: 100%;
    }

    .btn-icon {
      background: none;
      border: none;
      outline: none;

      cursor: pointer;

      @include on-event {
        outline: none;
      }
    }
  }
}