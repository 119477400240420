.favorites {
  padding-top: 36px;

  .section-favorites {
    padding-top: 26px;

    .items-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    
    .item-holder {
      margin-right: 5%;
      margin-bottom: 50px;
      width: 30%;
  
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  
    .image-holder {
      .fav-img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  
    .info-holder {
      padding-top: 20px;
  
      .title {
        margin-bottom: 0;
  
        font-size: 26px;
        font-weight: 600;
        letter-spacing: .6px;
      }
  
      .location-text {
        font-size: 19px;
        letter-spacing: .5px;
        color: $gray-144;
      }
  
      .desc {
        font-size: 18px;
        color: $gray-144;
      }
    }
  
    .price-holder {
      display: flex;
      align-items: center;
      padding-top: 7px;
  
      .old-price {
        position: relative;

        padding-right: 16px;
  
        font-size: 25px;
        color: $gray-144;

        .sign {
          padding-right: 7px;
        }

        &:after {
          content: '';

          position: absolute;
          top: 50%;
          right: 0;
          left: 3px;

          height: 2px;
          width: 72%;

          background-color: $gray-144;
        }
      }
  
      .new-price {
        padding-right: 16px;
  
        font-size: 32px;
        font-weight: 600;
        color: $blue;
      }
  
      .discount {
        padding: 0px 7px;
  
        font-size: 15px;
        text-transform: uppercase;
        color: $blue;
  
        background-color: $lavender-blue;
      }
    }
  }
}

@include respond-to(md-down) {
  .favorites {
    padding-top: 16px;

    .section-favorites { 
      padding-top: 12px;

      .item-holder {
        margin-right: 6%;
        width: 47%;
  
        &:nth-child(2n+2) {
          margin-right: 0;
        }
  
        &:nth-child(3n+3) {
          margin-right: 6%;
        }
      }
  
      .info-holder {
        .title {
          font-size: 20px;
        }
  
        .location-text {
          font-size: 16px;
        }

        .desc {
          font-size: 14px;
        }
      }
  
      .price-holder {
        padding-top: 8px;
  
        .old-price {
          font-size: 20px;
        }
  
        .new-price {
          font-size: 22px;
        }
      }
    }
  }
}

@include respond-to(xs) {
  .favorites {
    .section-favorites {
      .item-holder {
        margin-right: 0%;
        margin-bottom: 30px;
        width: 100%;
  
        &:nth-child(2n+2) {
          margin-right: 0;
        }
  
        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}